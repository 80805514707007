@media (max-width: 1210px) {
  body {
    overflow-x: hidden;
  }
}

@media (max-width: 1199px) {

  body {
    margin-left: -10px;
    margin-right: -10px;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .order-list .row, .site-form .row, .news-list__wrapper, .article-list__wrapper {
    margin-left: -10px;
    margin-right: -10px;
  }

  // основной хедер
  .header__login.misc, .header__login.user {
    order: 1;
    height: 42px;
    border-bottom: 1px solid #e1e1e1;
  }
  .header__counter {
    order: 3;
  }

  .header__social {
    order: 2;
    height: 42px;
    border-bottom: 1px solid #e1e1e1;
  }

  // основное меню
  .header__nav .nav__main {
    padding-left: 0;
    padding-right: 0;
  }
  .nav__main_menu {
    border-bottom: 1px solid #e1e1e1;
  }
  .header__nav .nav__main_search {
    position: relative;
    height: 49px;
    max-width: unset;
  }
  .header__nav .nav__main_order {
    max-width: unset;
  }
  .header__nav .nav__top_wrapper {
    padding-right: 10px;
  }

  .promo-inner__scale .no-padding {
    padding: 0 !important;
  }

  // фильтр новостей
  .news-list__filter_wrapper a {
    padding-left: 5px;
    padding-right: 5px;
  }

  // слайдер вертикальный
  .slider__side_item .btn {
    width: 99%;
    margin-left: auto;
    margin-right: auto;
  }

  // визитка поставщика
  .promo__scale:before {
    left: -30%;
  }

  // каталог
  .catalog__main {
    padding-left: 0;
    padding-right: 0;
  }

  // футер
  .footer__main {
    background-size: 85%;
    background-position: 70% 20%;
  }

  .order-item__right-col {
   border-right: 1px solid #e1e1e1;
   border-top: 1px solid #e1e1e1; 
  }

}

@media (max-width: 1023px) {

  // основная сетка страницы
  
  .left {

    // главная страница
    &__index {
      order: 1;
    }
    &__item {
      order: 2;
    }
    &__provider {
      order: 1;
    }
  }
  .center {

    // главная страница
    &__index {
      order: 3;
    }
    &__provider {
      order: 3;
    }
  }
  .right {

    // главная страница
    &__index {
      order: 2;
      & .banner__side-short {
        order: 1;
      }
      & .slider__side {
        order: 2;
      }
      & .partners {
        order: 4;
      }
      & .banner__side-long {
        order: 3;
      }
    }
    &__item {
      order: 1;
      & .item-card {
        order: 1;
      }
      & .banner__side-tiny {
        order: 2;
      }
    }
    &__provider {
      order: 2;
    }
    &__news-list {
      display: none;
    }
  }

  // основной хедер
  .header__logo a svg {
    width: 310px;
  }
  .header__contacts {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
  }
  .header__contacts_item {
    width: 100%;
    text-align: center;
    padding-left: 0;
  }
  .header__contacts_item.city a {
    left: -5px;
  }

  // блок новостей/статей
  .news-list__item .img, .article-list__item .img {
    height: 288px;
  }

  // превью товара
  .item-card {
    order: 1;
  }
  .item-card .img {
    min-height: 600px;
  }

  // галерея
  .mfp-pager .dots {
    display: none;
  }

  // визитка поставщика
  .promo__wrapper {
    max-width: unset;
  }
  .promo__scale:before {
    left: -13%;
  }

  // статистика
  .stats__item.social {
    margin-bottom: 5px;
  }

  // каталог
  .catalog__item h2 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .catalog__item a {
    padding-left: 15px;
    padding-right: 10px;
  }

  // футер
  .footer__main {
    background-size: 100%;
    background-position: 20% -30%;
  }
  .footer__menu {
    justify-content: flex-end;
  }
  .footer__menu_group {
    margin-left: 30px;
    &:first-child {
      margin-left: 0;
    }
  }
  .footer__contacts {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .footer__contacts_social {
    margin-bottom: 0;
  }
  .footer__contacts_phone {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
  }
  .footer__contacts_phone-item {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
  .left__orders-list {
    flex-direction: row;
  }
  .left__orders-list .sidebar {
    max-width: 50%;
    flex-basis: 50%;
  }

  .left__orders-list .banner__side-long {
    max-width: 50%;
    flex-basis: 50%;
  }

  .left__orders-list .banner__side-long {
    display: none;
  }
  .mobile-banner-block .banner__side-long {
    display: block;
    height: auto !important;
  }
}

// мобильное меню/хедер
@media (min-width: 769px) {
  .header__mob {
    display: none;
  }
  .header__mob-search {
    display: none;
  }
  .header__mob-search-wrapper {
    display: none !important;
  }
  .header__contacts_item.city a svg.geo {
    display: none;
  }
  .nav__main-mob {
    display: none !important;
  }
}

@media (max-width: 768px) {

  .banner__lg {
    padding-top: 0;
    margin-bottom: 0;
  }
  
  .header__main_top {
    height: 42px;
    border-bottom: none;
  }
  .header__counter {
    display: none !important;
  }
  .header__main_contacts {
    align-items: center;
    padding-top: 15px;
    padding-bottom: 10px;
    padding-left: 60px;
  }
  .header__contacts {
    margin-left: 0;
    margin-right: 0;
  }
  .header__contacts_item {
    text-align: left;
  }
  .header__contacts_item.phone {
    display: none;
  }
  .header__contacts_item.city a {
    left: 0;
    font-size: 16px;
  }
  .header__contacts_item.city a svg.down {
    display: none;
  }
  .header__contacts_item.city a svg.geo {
    top: 2px;
    right: -24px;
    width: 20px;
  }
  .header__contacts_item.city {
    position: relative;
    top: -5px;
    text-align: right;
    padding-right: 80px;
  }
  .header__contacts_item.city .caption {
    display: none;
  }
  .header__nav .nav__top_wrapper {
    display: none;
  }
  .header__nav .nav__main {
    display: none;
  }
  .header__contacts {
    align-items: flex-start;
  }

  .breadcrumb {
    padding-left: 16px;
  }

  .header__nav-ex .nav-ex__main_item a {
    padding-left: 10px;
    padding-right: 10px;
  }

  .header__mob_inner, .header__mob_inner.folded {
    width: 35px;
    height: 25px;
  }
  .news-list__switch {
    flex-direction: column;
  }

  .news-list__switch .selects-wrap {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .news-list-full--news .news-list__switch .selects-wrap .select2 {
    margin-left: 0;
    margin-right: 10px;
  }

  .left__orders-list {
    flex-direction: row;
  }
  .left__orders-list .sidebar {
    max-width: 50%;
    flex-basis: 50%;
  }

  .left__orders-list .banner__side-long {
    max-width: 50%;
    flex-basis: 50%;
  }

  .provider-card {
    position: relative;
    padding-bottom: 47px;
  }

  .provider-card__top {
    justify-content: flex-start;
    margin-bottom: 30px;
  }

  .provider-card__main {
    display: none;
  }

  .provider-card__counter {
    display: none;
  }

  .provider-card__more {
    position: absolute;
    bottom: 17px;
    left: 213px;
  }

}

@media (max-width: 767px) {

  body {
    margin-left: 0;
    margin-right: 0;
  }

  // основная сетка страницы
  
  .left {

    // главная страница
    &__index {
      order: 1 !important;
    }
  }
  .center {

    // главная страница
    &__index {
      order: 2 !important;
    }
  }
  .right {

    // главная страница
    &__index {
      order: 3 !important;
    }
  }

  // основной хедер
  .header__login {
    // max-width: 300px;
    z-index: 1;
  }
  .header__social {
    position: relative;
    top: -42px;
    display: none;
  }
  .header__counter {
    margin-top: -42px;
  }

  .header__main_contacts {
    padding-top: 15px;
    padding-left: 0;
  }
  .header__logo a svg {
    width: 170px;
    height: 21px;
  }

  // основное меню
  .nav__main_menu {
    flex-direction: column;
  }
  .header__nav .nav__main_item {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #e1e1e1;
    &:last-child {
      border-bottom: none;
    }
  }
  .header__nav .nav__main_sub a {
    background-color: #f0f0f0;
  }
  .header__nav .nav__main_item.multi span {
    left: 0;
  }

  // хедер подсайта
  .header-ex__logo a svg {
    max-width: 200px;
  }

  // блок новостей/статей
  .news-list__item .img, .article-list__item .img {
    height: 440px;
  }

  // карточка товара
  // .item-list {
  //   overflow-x: scroll;
  // }
  // .item-list__main {
  //   min-width: 715px;
  // }

  .item-list__main {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
  }
  .item-list__main_head {
    display: none;
  }
  .item-list__main_results {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .item-list__main_row {
    position: relative;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 46%;
    margin-right: 2%;
    margin-bottom: 20px;
    border: 1px solid #e1e1e1;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    &:nth-child(even) {
      margin-right: 0;
    }
  }
  .item-list__main_cell {
    display: block;
    min-width: 50%;
    border: none;
    text-align: left;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    margin-bottom: 5px;
    &.provider {
      width: 100%;
      order: 1;
    }
    &.phone {
      width: 100%;
      order: 3;
    }
    &.price {
      width: 100%;
      order: 4;
    }
    &.stats {
      width: 100%;
      order: 2;
      display: none;
      &.active {
        display: block;
      }
    }
    &.order {
      width: 100%;
      order: 5;
    }
  }
  .order-item__execution .item-list__main_cell {
    min-width: 100%;
  }
  .item-list__main_row a.expand {
    display: block;
  }
  .item-list__price_tooltip {
    top: 25px;
    left: -55px;
  }

  .item-list__price_tooltip.units {
    top: -7px;
    left: unset;
    right: 0;
  }

  // превью товара
  .item-card .img {
    min-height: 400px;
  }

  .item-card .btn.btn--primary.add {
    display: block;
  }

  .cabinet-menu-list {
    flex-wrap: wrap;
    &-item {
      width: 50%;
    }
  }
  .cabinet-stat__controls button.btn {
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 5px;
  }
  .cabinet-head-links a {
    display: block;
  }

  // список товаров
  // [все]
  .board-list {
    &__main {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      &_results {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        & .board-list__main_row {
          border: 1px solid #e1e1e1;
          margin-bottom: 20px;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
      &_row {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        width: 48%;
        margin-right: 2%;
        &:nth-child(even) {
          margin-right: 0;
        }
        & a.expand {
          display: block;
          z-index: 666;
        }
        &.filter-bar {
          flex-direction: row;
        }
      }
      &_cell {
        display: block;
        border: none;
        text-align: left;
        padding-left: 0;
        padding-right: 0;
        &.head {
          display: none;
        }
        &.filter {
          display: none;
          margin-bottom: 20px;
          margin-right: 5px;
          &.type {
            display: block;
            padding-left: 0;
          }
          &.standard {
            display: block;
          }
          &.label {
            display: block;
          }
          &.reset {
            display: block;
            padding-right: 0;
          }
        }
        &.stats {
          width: 100%;
          order: 1;
          padding-top: 0;
          padding-bottom: 0;
          margin-bottom: 10px;
        }
        &.type {
          width: 100%;
          order: 2;
          display: none;
        }
        &.standard {
          width: 100%;
          order: 3;
          display: none;
        }
        &.label {
          width: 100%;
          order: 4;
          display: none;
        }
        &.price {
          width: 100%;
          order: 5;
        }
        &.provider {
          width: 100%;
          order: 6;
          display: none;
        }
        &.order {
          width: 100%;
          order: 7;
        }
      }
      &_head {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-direction: column;
        & .board-list__main_cell.filter {
          &.type {
            width: auto;
            order: 1;
          }
          &.standard {
            width: auto;
            order: 2;
          }
          &.label {
            width: auto;
            order: 3;
          }
          &.reset {
            order: 4;
          }
        }
      }
    }
  }
  // [группировка]
  .board-list-group {
    & .board-list__main_results {
      flex-direction: row;
      flex-wrap: wrap;
    }
    & .board-list__main_row {
      width: 48%;
      margin-right: 2%;
      &:nth-child(even) {
        margin-right: 0;
      }
    }
    & .board-list__main_cell {
      text-align: left;
      padding-left: 0;
      &.stats {
        width: 100%;
        order: 1;
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 10px;
      }
      &.type {
        width: 100%;
        order: 2;
        display: none;
      }
      &.standard {
        width: 100%;
        order: 3;
        display: none;
      }
      &.label {
        width: 100%;
        order: 4;
        display: none;
      }
      &.price {
        width: 100%;
        order: 5;
      }
      &.provider {
        width: 100%;
        order: 6;
        text-align: left;
      }
      &.add {
        width: 100%;
        order: 7;
      }
      &.filter {
        display: block;
      }
    }
  }

  // визитка поставщика
  .promo__scale:before {
    left: 0;
  }

  .promo-inner {
    height: 250px;
    &__scale {
      height: 250px;
      &:after {
        content: '';
        position: absolute;
        top: -36px;
        right: 10%;
        width: 400px;
        height: 100%;
        background-color: #fff;
        -webkit-transform: rotate(-70deg);
        transform: rotate(-70deg);
        z-index: 1;
      }
    }
    &__wrapper {
      align-items: flex-start;
    }
  }

  // карточка поставщика
  .provider-card {
    &__main {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      &_results {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-direction: column;
        & .board-list__main_row {
          border: 1px solid #e1e1e1;
          margin-bottom: 20px;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
      &_row {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border: 1px solid #e1e1e1;
        margin-bottom: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 16px;
        padding-right: 16px;
      }
      &_cell {
        display: block;
        border: none;
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        &.head {
          display: none;
        }
        &.filter {
          display: none;
          margin-bottom: 20px;
          &.type {
            display: block;
            padding-left: 0;
          }
          &.standard {
            display: block;
          }
          &.label {
            display: block;
          }
          &.reset {
            display: block;
            padding-right: 0;
          }
        }
        &.stats {
          width: 25%;
          order: 1;
          margin-bottom: 0;
        }
        &.type {
          width: 15%;
          order: 2;
        }
        &.standard {
          width: 20%;
          order: 3;
        }
        &.label {
          width: 20%;
          order: 4;
        }
        &.price {
          width: 20%;
          order: 5;
        }
        &.order {
          width: 100%;
          order: 7;
          text-align: left;
        }
      }
    }
  }

  // каталог
  .catalog__col:nth-child(even) {
    border-right: none;
  }
  .catalog__item h2 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .catalog__item a {
    padding-left: 25px;
    padding-right: 20px;
  }

  // футер
  .footer__copyright {
    flex-direction: row;
    margin-bottom: 30px;
  }
  .footer__copyright_logo {
    width: 50%;
    max-width: 300px;
    padding-right: 20px;
  }
  .footer__copyright_caption {
    width: 50%;
  }
  .footer__menu {
    justify-content: space-around;
  }
  .footer__sub .webmotor {
    text-align: left;
  }

  // выбор города
  #city {
    max-width: 300px;
  }

  // футер подсайта
  .footer-ex__copyright {
    order: 1;
  }
  .footer-ex__menu {
    order: 2;
  }

  .footer-ex__sub .warning, .footer-ex__sub .webmotor, .footer-ex__sub .company, .footer-ex__sub .policy {
    text-align: center;
  }

  .cabinet-rate-form:after {
    content: none;
  }

  /*news*/
  .news-title {
    flex-direction: column;
  }
  .news-title h1,
  .news-title .h1 {
    flex-grow: 0;
    flex-basis: 100%;
    min-width: 100%;
    margin-bottom: 5px;
    & .tag-wrap {
      display: block;
      min-width: 100%;
      flex-basis: 100%;
      text-align: right;
    }
  }
  .news-title .news-date {
    flex-grow: 0;
    flex-basis: 100%;
    min-width: 100%;
    padding-bottom: 10px;
  }
}

@media (max-width: 619px) {

  // основной хедер
  .header__contacts {
    flex-direction: column;
  }

  // блок новостей/статей
  .news-list__item .img, .article-list__item .img {
    height: 350px;
  }
}

@media (min-width: 580px) {
  .header-ex__mob {
    display: none !important;
  }
  .nav-ex__main-mob {
    display: none !important;
  }
}

@media (max-width: 579px) {

  // основной хедер
  .header__main_top {
    height: auto;
  }
  .header__login {
    max-width: unset;
    z-index: unset;
  }
  .header__login.misc {
    justify-content: space-between;
    padding-right: 0;
  }
  .header__login.misc a {
    &:last-child {
      border-left: 1px solid #e1e1e1;
      border-right: none;
    }
  }
  .header__login.user {
    padding-right: 0;
  }
  .header__login.user > a {
    border-right: none;
  }
  .header__login_dropdown {
    z-index: 3;
  }
  .header__social {
    position: static;
    justify-content: center;
    padding-left: 0;
    top: unset;
    order: 3;
    border-bottom: 1px solid #e1e1e1;
  }
  .header__social a {
    border-left: none;
    border-right: 1px solid #e1e1e1;
    &:first-child {
      border-left: 1px solid #e1e1e1;
    }
  }
  .header__counter {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 0;
    order: 2;
  }
  .header__counter_group {
    display: block;
  }
  
  .header__main_contacts {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 4px;
  }
  .header__contacts {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    // padding-top: 16px;
  }
  .header__contacts_item {
    // text-align: center;
  }

  .header__contacts {
    padding-top: 0;
    height: 0;
  }

  .header__contacts_item.city {
    position: absolute;
    width: 65%;
    top: -33px !important;
    left: 50%;
    transform: translateX(-50%);
  }
  .header__contacts_item.city {
    text-align: center;
    padding-right: 0;
    & .fire-region {
      position: relative;
      left: -7px;
    }
  }
  .header__logo {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    margin-bottom: 10px;
  }
  .header__logo a {
    justify-content: center;
  }
  .header__mob {
    left: 7px;
  }
  .header__mob-search {
    right: 5px;
  }

  // основное меню
  .nav__main_search {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .nav__main_order {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    height: 49px;
  }
  .nav__main_search-wrapper {
    display: block !important;
  }
  .header__nav .nav__main_search a {
    border-right: none;
  }

  // хедер подсайта
  .header-ex {
    margin-top: 0;
    margin-bottom: 0;
  }

  .header-ex__main {
    // height: 100px;
    height: 50px;
  }
  .header-ex__logo {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    text-align: center;
  }
  .header-ex__login {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    border-top: 1px solid #6e6e6e;

    display: none;
  }
  // меню подсайта
  .header__nav-ex {
    // margin-bottom: 0;
  }
  .nav-ex__main {
    // display: none !important;
  }

  // сайдбар
  .sidebar #accordion {
    display: none;
  }

  // блок новостей/статей
  .news-list__item .img, .article-list__item .img {
    height: 326px;
  }

  // фильтр новостей
  .news-list__filter {
    flex-direction: column;
  }

  // фильтр простой
  .filter-simple {
    flex-direction: column;
    align-items: flex-start;
    & h1, & .h1 {
      margin-bottom: 20px;
    }
  }

  // список товаров
  // [все]
  .board-list__main_row {
    width: 100%;
    margin-right: 0;
  }
  .board-list__main_results .board-list__main_cell {
    text-align: left;
    &.stats {
      width: 100%;
      order: 1;
    }
    &.type {
      width: 50%;
      order: 2;
    }
    &.standard {
      width: 50%;
      order: 3;
    }
    &.label {
      width: 50%;
      order: 4;
    }
    &.price {
      width: 50%;
      order: 4;
    }
    &.provider {
      width: 100%;
      order: 5;
    }
    &.order {
      width: 100%;
      order: 6;
    }
  }
  // [группировка]
  .board-list-group {
    & .board-list__main_row {
      width: 100%;
      margin-right: 0;
    }
    & .board-list__main_cell {
      text-align: left;
      &.stats {
        width: 100%;
        order: 1;
      }
      &.type {
        width: 33%;
        order: 2;
      }
      &.standard {
        width: 33%;
        order: 3;
      }
      &.label {
        width: 33%;
        order: 4;
      }
      &.price {
        width: 100%;
        order: 5;
      }
      &.provider {
        width: 100%;
        order: 6;
      }
      &.add {
        width: 100%;
        order: 7;
      }
    }
  }

  .board-list__main_row.filter-bar {
    flex-wrap: wrap;
  }

  .board-list-group .board-list__main_cell.filter {
    margin-bottom: 0;
  }
  .board-list__main_cell.filter .select2-container {
    min-width: 80px !important;
    width: 80px !important;
  }
  .board-list__main_head .board-list__main_cell.filter.reset {
    position: absolute;
    top: 2px;
    right: -10px;
  }

  // карточка товара
  .item-list__main_row {
    width: 100%;
    margin-right: 0;
  }

  // превью товара
  .item-card .img {
    min-height: 350px;
  }

  // визитка поставщика
  .promo {
    height: auto;
  }
  .promo__scale {
    height: auto;
  }
  .promo__scale:before {
    transform: none;
    left: unset;
  }
  .promo__scale > div {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .promo__wrapper {
    padding-top: 16px;
    padding-bottom: 16px;
    justify-content: flex-start;
  }
  .promo__logo {
    margin-bottom: 10px;
  }
  .promo__adress {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .promo__phone {
    line-height: 1;
    margin-bottom: 10px;
  }

  .promo-inner__scale:after {
    content: '';
    position: absolute;
    top: -255px;
    right: -10%;
    width: 1000px;
    height: 1000px;
    background-color: #fff;
    -webkit-transform: rotate(-70deg);
    transform: rotate(-70deg);
    z-index: 1;
  }

  .promo-inner__phone {
    font-size: 20px;
  }

  // карточка поставщика
  .provider-card {
    &__main {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      display: none;
      &_row {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border: 1px solid #e1e1e1;
        margin-bottom: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 16px;
        padding-right: 16px;
      }
      &_cell {
        display: block;
        border: none;
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        &.head {
          display: none;
        }
        &.stats {
          width: 50%;
          order: 1;
          text-align: left;
        }
        &.type {
          width: 50%;
          order: 2;
          text-align: left;
        }
        &.standard {
          width: 50%;
          order: 3;
          text-align: left;
        }
        &.label {
          width: 50%;
          order: 4;
          text-align: left;
        }
        &.price {
          width: 50%;
          order: 5;
          text-align: left;
        }
        &.order {
          width: 50%;
          order: 7;
          text-align: left;
        }
      }
    }
  }

  // каталог
  .catalog__col {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    border-right: none;
  }

  // футер
  .footer__copyright {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .footer__copyright_logo {
    width: 100%;
    padding-right: 0;
  }
  .footer__copyright_caption {
    width: 100%;
  }
  .footer__menu {
    justify-content: space-between;
  }
  .footer__contacts {
    flex-direction: column;
  }
  .footer__contacts_social {
    justify-content: center;
    margin-bottom: 20px;
  }
  .footer__contacts_phone {
    justify-content: space-between;
  }

  .footer-ex__main {
    display: none;
  }
  
  .left__orders-list {
    flex-direction: column;
  }
  .left__orders-list .sidebar {
    max-width: 100%;
    flex-basis: 100%;
  }

  .left__orders-list .banner__side-long {
    max-width: 100%;
    flex-basis: 100%;
  }
}

@media (max-width: 460px) {
  .item-list__main_cell.phone {
    display: inline-block;
    width: 50%;
  }
  .item-list__main_cell.order {
    display: inline-block;
    width: 50%;
  }
}

@media (max-width: 419px) {

  // основной хедер
  .header__main_contacts {
    padding-left: 16px;
    padding-right: 16px;
  }
  .header__contacts_item {
    text-align: left;
  }

  // хедер подсайта
  .header-ex__logo a {
    position: relative;
    left: 16px;
  }

  // верхнее меню
  .nav__top_wrapper {
    flex-direction: column;
  }
  .header__nav .nav__top_item:first-child {
    margin-left: 18px;
  }

  // меню подсайта

  .header__nav-ex .nav-ex__main_menu {
    justify-content: space-between;
  }
  .header__nav-ex .nav-ex__main_item a {
    padding-left: 0;
    padding-right: 0;
    &:hover {
      color: #a73439;
      background-color: #fff;
    }
  }
  .header__nav-ex .nav-ex__main_item {
    border-right: none;
  }
  .header__nav-ex .nav-ex__main_item:first-child a {
    padding-left: 10px;
  }
  .header__nav-ex .nav-ex__main_item:last-child a {
    padding-right: 10px;
  }

  // блок новостей/статей
  .news-list__item .img, .article-list__item .img {
    height: 227px;
  }

  // фильтр новостей
  .news-list__filter_wrapper a {
    margin-right: 4px;
  }

  // карточка товара
  .item-list__main_cell {
    &.provider {
      width: 100%;
      margin-bottom: 15px;
    }
    &.phone {
      display: inline-block;
      width: 50%;
      min-width: unset;
      order: 4;
    }
    &.price {
      width: 100%;
      order: 3;
      margin-bottom: 30px;
    }
    &.stats {
      width: 100%;
      margin-bottom: 25px;
    }
    &.order {
      display: inline-block;
      width: 50%;
      min-width: unset;
    }
  }
  .item-list__price_tooltip {
    left: 0;
  }

  .item-list__top .select2-container {
    position: relative;
    margin-bottom: 20px;
  }

  // превью товара
  .item-card .img {
    min-height: 270px;
  }

  // фильтр простой
  .filter-simple__group a {
    min-width: unset;
    margin-left: 5px;
  }

  // список товаров
  // [все]
  .board-list__main_results .board-list__main_cell {
    text-align: left;
    &.stats {
      width: 100%;
      order: 1;
    }
    &.type {
      width: 100%;
      order: 2;
    }
    &.standard {
      width: 100%;
      order: 3;
    }
    &.label {
      width: 100%;
      order: 4;
    }
    &.price {
      width: 100%;
      order: 4;
    }
    &.provider {
      width: 100%;
      order: 5;
    }
    &.order {
      width: 100%;
      order: 6;
    }
  }
  // [группировка]
  .board-list-group .board-list__main_cell.provider {
    text-align: left;
  } 

  // визитка поставщика
  .promo__phone {
    font-size: 20px;
  }

  // список поставщиков
  .provider-card__more {
    left: unset;
    right: 16px;
  }

  // футер

  .footer__copyright {
    margin-bottom: 15px;
  }
  .footer__copyright_caption p {
    line-height: 1.2; 
  }
  .footer__menu {
    flex-direction: column;
    align-items: center;
  }
  .footer__menu_group {
    margin-left: 0;
    margin-bottom: 15px;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .footer__menu_group-heading {
    margin-bottom: 10px;
  }
  .footer__menu_group-item {
    line-height: 1.3;
  }
  .footer__contacts_phone {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .footer__contacts_phone-item {
    margin-right: 0;
  }

  // форма авторизации
  #login {
    .row:last-child .site-form-element-wrapper {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
    }
  }
}

@media (max-width: 320px) {

  // блок новостей/статей
  .news-list__item .img, .article-list__item .img {
    height: 166px;
  }
}

@media (max-width: 320px) {
  .item-list__main_row {
    // flex-direction: column;
    // align-items: flex-start;
  }
  .item-list__main_cell {
    .head {
      display: block;
      font-weight: 600;
    }
  }
}