.bg-wrapper {
    background-color: #fff;
    border: 1px solid #e1e1e1;
    padding-top: 23px;
    padding-bottom: 14px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 20px;
}
.cabinet-logo {
  max-width: 100%;
}

.cabinet-menu-list {
  display: flex;
  &-item {
    padding-right: 20px;
    display: inline-block;
    &__link {
      font-family: 'robotoslab';
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 33px;
    }
  }
}

.cabinet-head {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 7px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  h1, & .h1 {
    border-bottom: none;
    margin-bottom: 0;
    margin-right: auto;
  }
  & button {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 5px;
    &:first-child {
      margin-left: 0;
    }
  }
  & form {
    margin-left: 5px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    & form {
      width: 100%;
      margin-left: 0;
      & button {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
    & button {
      width: 100%;
      margin-left: 0;
      margin-bottom: 5px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.cabinet-head-links {
  a {
    margin-left: 15px;
  }
}

.cabinet-company {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 5px;
}

.cabinet-item__name {
  font-size: 14px;
}

.cabinet-item-block {
  .h1, h1 {
    border-bottom: none;
  }
  .form-row input {
    display: block;
    width: 100%;
    position: relative;
    z-index: 5;
    height: 30px;
    line-height: 30px;
    font-weight: 300;
    color: #4f4f4f;
    font-size: 16px;
    padding: 0 8px;
    border: 1px solid #adadad;
    background-color: #f6f6f6;
  }

  .form-row input:focus {
    outline: 0;
  }


  .form-row input:focus ~ .label__caption,
  .form-row.filled .label__caption {
    opacity: 1;
    font-size: 16px;
    top: -30%;
    background: #fbfbfc;
    z-index: 22;
    height: 20px;
    line-height: 1;
  }

  .form-row input::-webkit-input-placeholder {opacity:0;}
  .form-row input::-moz-placeholder          {opacity:0;}/* Firefox 19+ */
  .form-row input:-moz-placeholder           {opacity:0;}/* Firefox 18- */
  .form-row input:-ms-input-placeholder      {opacity:0;}

  .form-row input:focus::-webkit-input-placeholder {opacity:1;}
  .form-row input:focus::-moz-placeholder          {opacity:1;}/* Firefox 19+ */
  .form-row input:focus:-moz-placeholder           {opacity:1;}/* Firefox 18- */
  .form-row input:focus:-ms-input-placeholder      {opacity:1;}


  /* FORM STYLE */
  .form-row label.error {
    display: inline-block;
    position: static;
    color: #D10B0D;
  }

  .form-row input.error {
    // background: @white-color;
    // color: @text-color;
    border-bottom: 2px solid #D10B0D;
  }

  ::-webkit-input-placeholder {color:#615f5f;opacity:1;}
  ::-moz-placeholder          {color:#615f5f;opacity:1;}/* Firefox 19+ */
  :-moz-placeholder           {color:#615f5f;opacity:1;}/* Firefox 18- */
  :-ms-input-placeholder      {color:#615f5f;opacity:1;}

  .callback-form-button-wrapper {
    text-align: center;
  }

  .site-form-row--checkbox {
    margin-bottom: 5px;
  }
  .site-form__icon-checked {
    fill: #a73439;
  }
  .site-form__input-checkbox:focus ~ .site-form__label-checkbox {
    border-color: #a73439;
  }
  .site-form-checkbox-text {
    font-size: 13px;
  }
  margin-bottom: 30px;
  .h3 {
    font-size: 15px;
  }
  .table {
    min-width: 100%;
    tr {
      border-bottom: 1px solid;
    }
    a {
      font-size: 16px;
    }
  }
  table.table th {
    padding: 6px 15px 6px 0;
    font-weight: 700;
    color: #000;
    text-align: left;
    vertical-align: middle;
  }
  table.table td {
    padding: 6px 15px 6px 0;
    border-bottom: 1px solid #9d9e9e;
    .h2 {
      margin-bottom: 0;
    }
    span {
      font-size: 13px;
      display: block;
      color: #555;
    }
  }

}

.cabinet-controls {
  margin-bottom: 30px;
}

.cabinet-stat__controls,  {
  margin: 10px 0;
  display: flex;
  button.btn {
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-width: 1px;
    border-style: solid;
    border-radius: 0;
    font-family: 'robotoslab';
    font-size: 14px;
    margin-right: 10px;
    &.active, &:hover {
      border-color: #a73439;
      background-color: #a73439;
      color: #fff;
    }
  }
}

.cabinet-controls {
  text-align: center;
  a.btn {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 9px;
    padding-right: 9px;
    font-size: 15px;
    &.active, &:hover {
      border-color: #a73439;
      background-color: #a73439;
      color: #fff;
    }
  }
}

.cabinet-rate {
  &__name {
    display: flex;
    margin-bottom: 30px;
  }
  &-form {
    background: url('../img/cabinet/individual-rate-bg.jpg') bottom left / cover no-repeat;
    margin-left: -16px;
    margin-right: -16px;
    padding: 42px 16px;
    margin-bottom: 35px;
    position: relative;
    &:after {
      content: '';
      display: block;
      background: url('../img/cabinet/individual-rate-after.png') center center / cover no-repeat;
      width: 402px;
      height: 226px;
      position: absolute;
      right: 0;
      bottom: -42px;
    }
    .h1, h1 {
      color: white;
      border-bottom: none;
      margin-bottom: 2px;
    }
    .p {
      color: #fefefe;
      font-family: 'proxima_nova';
      font-size: 14px;
      opacity: 0.8;
      font-weight: 400;
      line-height: 21px;
    }
  }
  &__status {
    color: black;
    font-family: 'proxima_nova';
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-decoration: underline;
    margin-left: 14px;
  }
}

.cabinet-rate-item {
  margin-bottom: 30px;
  &__head {
    background-color: #272625;
    background-image: linear-gradient(to right, #2a2928 0%, black 100%);
    border-radius: 10px 10px 0px 0px;
    color: #fefefe;
    font-family: RobotoSlab;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    padding: 15px; 
  }
  &-list {
    border: 1px solid #e6e6e6;
    border-bottom: none;
  }
  &-list-item {
    opacity: 0.8;
    color: black;
    padding: 13px;
    text-align: center;
    font-family: 'proxima_nova';
    font-size: 14px;
    border-bottom: 1px solid #e6e6e6;
    font-weight: 400;
    line-height: 21px;
    &--title {
      font-size: 16px;
    }
  }
  &-footer {
    border: 1px solid #e6e6e6;
    border-top: none;
    text-align: center;
    padding: 20px 40px 35px;
    .btn {
      width: 100%;
    }
  }
}

// для таблиц(моб.)
.scroll-table {
  width: 100%;
  overflow-x: auto;
  table th {
    min-width: 150px;
  }

}