html,body {

  overflow-x: hidden;

}

body {
  width: 100%;
}
.header--fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  & .header__main {
    padding-left: 0;
    padding-right: 0;
    border: none;
    border-bottom: 1px solid #e1e1e1;
  }
}

.header__nav--fixed {
  position: fixed;
  top: 93px;
  left: 0;
  width: 100%;
  z-index: 999;
  & .nav__main-mob {
    padding-left: 0;
    padding-right: 0;
    border: none !important;
    border-bottom: 1px solid #e1e1e1;
  }
}

.header__nav-d--fixed {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  z-index: 999;
}


/**
* header mobile start
* -----------------------------------------------------------------------------
*/
.header-mobile {
  /**
  * локальные переменные
  * переназначайте их как угодно и где угодно
  */
  @mobile-header-element-padding-left:  10px; /* отступ элементов слева */
  @mobile-header-element-padding-right: 10px; /* отступ элементов справа */


  align-items: center;
  height: 70px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: none;

  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);

  &-menu-button-wrapper {
    display: none;
  }

  &-menu-inner-wrapper {
    flex-direction: column;
    display: flex;
    height: 100%;
    padding-bottom: 20px;
    overflow-y: scroll;
  }

  &-nav {
    margin-bottom: 30px;
  }

  &-menu {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: calc(~"100vh -" 70px);
    background-color: @gray;

    & .btn {
      align-self: flex-start;
      min-height: 47px;
      margin-right: @mobile-header-element-padding-right;
      margin-left: @mobile-header-element-padding-left;
    }
  }

  &-content-wrapper {
    display: flex;
    align-items: center;
    padding-left: @mobile-header-element-padding-left;
  }

  &-logo-wrapper {
    height: 100%;
    margin-right: 30px;
  }

  &-logo {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 54px;
  }

  &__phone {
    position: relative;
    top: 2px;
    display: inline-block;
    color: @text-color;
    text-decoration: none;
    line-height: 1;

    &:active {
      color: @link-active;
    }
  }

  &-phone-wrapper {
    height: 100%;
  }

  &-nav-list {
    display: block;
  }

  &-nav-item {
    border-bottom: 1px solid @gray--darken;
    &:last-child {
      border-bottom: 0;
    }
  }

  &-nav__link {
    display: block;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: @mobile-header-element-padding-left;
    padding-right: @mobile-header-element-padding-right;

    line-height: 24px;
    color: #000;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;

    &.active {
      color: @link-hover;
    }
  }

  &__menu-button {
    display: block;
    padding: 0;
    border: 0;
    position: relative;
    width: 70px;
    height: 70px;

    background-color:  @gray;
    text-decoration: none;

    &:focus { outline: 0; }

    & div {
      position: absolute;
      left: 50%;
      margin-top: 23px;
      margin-left: -15px;
      width: 30px;
      height: 2px;
      background-color: @text-color;
      transition: 0.3s transform ease;
    }

    & div:nth-child(1) { top: 0; }
    & div:nth-child(2) { top: 11px; }
    & div:nth-child(3) { top: 21px; }

    &.active {
      background-color:  @gray;

      & div {
        &:nth-child(1) {
          transform: rotate(45deg);
          transition: 0.3s all ease;
          top: 10px;
        }

        &:nth-child(2) {
          opacity: 0;
          transition: 0.3s opacity ease;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
          transition: 0.3s all ease;
          top: 10px;
        }
      }
    }
  }
  &-menu-phone-wrapper {
    margin-bottom: 40px;
    padding-left: @mobile-header-element-padding-left;
    padding-right: @mobile-header-element-padding-right;
  }
  &-menu__phone {
    position: relative;
    top: 2px;
    display: inline-block;
    color: #424141;
    text-decoration: none;
    line-height: 1;
  }
}

/*
@media (max-width:1024px) {
  body {
    padding-top: 70px;
  }
  .header {
    z-index: 100;
    height: 70px;
  }
  .header-mobile {
    display: flex;
  }
  .header-inner-wrapper {
    display: none;
  }
  .header-mobile-menu-button-wrapper {
    display: block;
  }
}
// header mobile close
*/

// main

body {
  position: relative;
  background-color: #f0f0f0;
  letter-spacing: -0.8px;
  overflow-x: hidden;
}

// декоративные элементы

.decoration {
  background-image: url('../img/multiples/pointless-body.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 700px;
  height: 700px;
  overflow: hidden;
  z-index: -1;
  &.right {
    position: absolute;
    display: block;
    top: 300px;
    right: -270px;
  }
  &.left {
    position: absolute;
    display: block;
    bottom: 300px;
    left: -270px;
  }
  @media (max-width: 1199px) {
    display: none !important;
  }
}

.multiple {
  &__order {
    position: fixed;
    top: 350px;
    right: -15px;
    width: 80px;
    height: 80px;
    z-index: 9;
    & svg {
      width: 80px;
      height: 80px;
    }
    @media (max-width: 1199px) {
      display: none;
    }
  }
  &__purchase {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 777;
    & a {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      height: 100%;
      font-family: 'robotoslab';
      color: #fff;
      text-decoration: none;
      background-color: #b73439;
      transition: background ease-in-out 0.2s;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
      &:visited {
        color: #fff;
        background-color: #b73439;
      }
      &:active {
        background-color: #a73449;
      }
      &:hover {
        background-color: #a73449;
      }
    }
    @media (min-width: 769px) {
      display: none;
    }
  }
}

// баннеры

.banner {

  // горизонтальный широкий
  &__lg {
    width: 100%;
    padding-top: 25px;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    margin-bottom: 10px;
    & img, & ins, & iframe, & canvas {
      display: block;
      width: 100% !important;
      height: auto !important;
      margin: 0 !important;
      padding: 0 !important;
      max-width: 100% !important;
      min-width: 100% !important;
    }
  }

  // горизонтальный широкий
  &__sm {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 18px;
    & img {
      width: 100%;
      height: auto;
    }
  }

  // горизонтальный в таблице
  &__table {
    width: 100%;
    & img {
      width: 100%;
      height: auto;
    }
  }

  // вертикальный высокий
  &__side-long {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    & img {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      height: auto;
    }
  }
  // вертикальный низкий
  &__side-short {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    & img {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      height: auto;
    }
  }

  // вертикальный в новостях
  &__news {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 18px;
    padding-top: 14px;
    & img {
      width: 100%;
      height: auto;
    }
  }

  // вертикальный мельчайший
  &__side-tiny {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    
    & img {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      height: auto;
    }
  }
}

.banner__side-place {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 23px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  &__wrapper {
    padding: 0 25px;
    .banner {
      margin-bottom: 17px;
    }
  }
}

.right {
  &__item {
    &.sticky {
      position: -webkit-sticky;
      position: -moz-sticky;
      position: -ms-sticky;
      position: -o-sticky;
      position: sticky;
      width: 100%;
      margin-bottom: 20px;
    }
    & .banner__side-tiny {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
}


// хедер

.header {
  &__main {
    background-color: #fff;
    border: 1px solid #e1e1e1;

    // верхняя панель
    &_top {
      border-bottom: 1px solid #e1e1e1;
    }
    // основная панель
    &_contacts {
      position: relative;
      padding-top: 26px;
      padding-bottom: 18px;
    }
  }

  // блок авторизации
  &__login {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0;
    // юзер не авторизован 
    &.misc {

      // вкл.выкл, исключает user
      /* display: none; */

      & a {
        position: relative;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        align-items: center;
        height: 100%;
        color: #000;
        text-decoration: none;
        border-right: 1px solid #e1e1e1;
        padding-left: 37px;
        padding-right: 9px;
        transition: background ease-in-out 0.2s;
        &:visited {
          color: #000;
          text-decoration: none;
        }
        &:active {
          color: #000;
          text-decoration: none;
          background-color: #fafafa;
        }
        &:hover {
          color: #000;
          text-decoration: none;
          background-color: #fafafa;
        }

        & svg {
          position: absolute;
          top: 14px;
          left: 11px;
          height: 15px;
          fill: #555555;

          // IE fix
          width: 20px;
        }
      }
    }

    // юзер авторизован
    &.user {

      // вкл.выкл, исключает misc
      display: none;

      position: relative;
      & > a {
        position: relative;
        height: 100%;
        padding-left: 37px;
        padding-right: 37px;
        white-space: nowrap;
        width: 100%;
        max-width: 270px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        color: #000;
        text-decoration: none;
        border-right: 1px solid #e1e1e1;
        padding-top: 10px;
        padding-bottom: 8px;
      }
      
      & svg {
        position: absolute;
        width: 16px;
        fill: #000;
        transition: all ease-in-out 0.2s;
        &.user_icon {
          top: 13px;
          left: 10px;
        }
        &.user_arrow {
          top: 14px;
          right: 13px;
        }
      }
      & a.account {
        transition: all ease-in-out 0.2s;
        &:visited {
          color: #000;
          text-decoration: none;
        }
        &:active {
          color: #7f7f7f;
          text-decoration: none;
          & svg {
            fill: #7f7f7f;
          }
        }
        &:hover {
          color: #7f7f7f;
          text-decoration: none;
          & svg {
            fill: #7f7f7f;
          }
        }
        &.active {
          color: #7f7f7f;
          text-decoration: none;
          & svg {
            fill: #7f7f7f;
          }
          & svg.user_arrow {
            transform: rotate(180deg);
          }
        }
      }
      & a.exit, a.profile {
        position: relative;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 35px;
        width: 100%;
        height: 45px;
        color: #7f7f7f;
        text-decoration: none;
        background-color: #fff;
        transition: background ease-in-out 0.2s;
        & svg {
          position: absolute;
          fill: #7f7f7f;
          top: 14px;
          left: 10px;
        }
        &:hover {
          background-color: #fafafa;
        }
      }
    }
    &_dropdown {
      position: absolute;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      left: -1px;
      bottom: -92px;
      width: 100%;
      max-width: 271px;
      height: 92px;
      border: 1px solid #e1e1e1;
      background-color: #fff;
      z-index: 1;
      display: none;
    }
  }

  // счетчик
  &__counter {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 8px;
    &_value {
      font-family: 'robotoslab';
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0;
      color: #9a1a06;
    }
  }

  // социальные сети
  &__social {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 0;
    & a {
      position: relative;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      border-left: 1px solid #e1e1e1;
      padding-left: 16px;
      padding-right: 16px;
      transition: background ease-in-out 0.2s;
      &:active {
        background-color: #fafafa;
      }
      &:hover {
        background-color: #fafafa;
      }

      & svg {
        width: 16px;
        height: 16px;
        fill: #6a6a6a;
      }
    }
  }

  // логотип
  &__logo {
    position: relative;
    & a {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      // IE fix
      max-height: 54px;
      & svg {
        width: 100%;
      }
    }
  }

  // регион и контакты
  &__contacts {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &_item {
      text-align: right;
      padding-left: 40px;
      &:first-child {
        padding-left: 0;
      }
      & a {
        position: relative;
        font-family: 'robotoslab';
        font-weight: 700;
        font-size: 20px;
        color: #000;
        text-decoration: none;
        &:visited {
          color: #000;
          text-decoration: none;
        }
        &:active {
          color: #000;
          text-decoration: none;
        }
        &:hover {
          color: #000;
          text-decoration: none;
        }
        & svg {
          position: absolute;
          top: 8px;
          right: -20px;
          width: 15px;
        }
      }
      &.city {
        & a {
          position: relative;
          left: -20px;
        }
      }
      &.phone {
        letter-spacing: 0;
      }
    }
  }
  &__mob {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 16px;
    top: 13px;
    z-index: 2;
    &_inner {
      width: 42px;
      height: 30px;
      border: none;
      background-color: transparent;
      background-image: url('../img/icons/menu_close.svg');
      background-repeat: no-repeat;
      background-size: contain;
      outline: none;
      &.folded {
        background-size: cover;
        background-image: url('../img/icons/menu.svg');
      }
    }
  }
  &__mob-search {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 16px;
    top: 9px;
    z-index: 2;
    &_inner {
      width: 30px;
      height: 30px;
      border: none;
      background-color: transparent;
      background-image: url('../img/icons/right-arrow.svg');
      background-repeat: no-repeat;
      background-size: contain;
      outline: none;
      &.folded {
        background-image: url('../img/icons/search.svg');
      }
    }
  }
}

// хедер подсайта

.header-ex {
  margin-top: 30px;
  margin-bottom: 20px;
  &__main {
    height: 50px;
    background-color: #2b2a29;
  }

  // логотип
  &__logo {

    position: relative;

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    
    justify-content: flex-start;
    align-items: center;
    
    @media screen and(max-width: 580px){

      justify-content: center;

    }

    & a {
      

      // IE fix
      max-height: 54px;
      & svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  // блок авторизации
  &__login {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 0;

    display: none;
    // юзер не авторизован 
    &.misc {

      // вкл.выкл, исключает user
      /* display: none; */

      & a {
        position: relative;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        align-items: center;
        height: 100%;
        color: #fff;
        text-decoration: none;
        border-left: 1px solid #6e6e6e;
        padding-left: 37px;
        padding-right: 9px;
        transition: background ease-in-out 0.2s;
        &:visited {
          color: #000;
          text-decoration: none;
        }
        &:active {
          color: #000;
          text-decoration: none;
          background-color: #fafafa;
        }
        &:hover {
          color: #fff;
          text-decoration: none;
          background-color: #1a1a1a;
        }

        & svg {
          position: absolute;
          top: 17px;
          left: 11px;
          height: 15px;
          fill: #e9e9e9;

          // IE fix
          width: 20px;
        }
      }
    }

    // юзер авторизован
    &.user {

      // вкл.выкл, исключает misc
      display: none;

      position: relative;
      & > a {
        position: relative;
        height: 100%;
        padding-left: 37px;
        padding-right: 37px;
        white-space: nowrap;
        width: 100%;
        max-width: 270px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        color: #fff;
        text-decoration: none;
        padding-top: 15px;
        padding-bottom: 8px;
      }
      
      & svg {
        position: absolute;
        width: 16px;
        fill: #e9e9e9;
        transition: all ease-in-out 0.2s;
        &.user_icon {
          top: 17px;
          left: 10px;
        }
        &.user_arrow {
          top: 17px;
          right: 13px;
        }
      }
      & a.account {
        transition: all ease-in-out 0.2s;
        &:visited {
          color: #000;
          text-decoration: none;
        }
        &:active {
          color: #7f7f7f;
          text-decoration: none;
          & svg {
            fill: #7f7f7f;
          }
        }
        &:hover {
          color: #7f7f7f;
          text-decoration: none;
          & svg {
            fill: #7f7f7f;
          }
        }
        &.active {
          color: #7f7f7f;
          text-decoration: none;
          & svg {
            fill: #7f7f7f;
          }
          & svg.user_arrow {
            transform: rotate(180deg);
          }
        }
      }
      & a.exit {
        position: absolute;
        bottom: -46px;
        height: 46px;
        color: #7f7f7f;
        background-color: #2b2a29;
        border-top: 1px solid #7f7f7f;
        z-index: 1;
        transition: background ease-in-out 0.2s;
        z-index: 4;
        display: none;
        & svg {
          position: absolute;
          top: 14px;
          left: 10px;
          fill: #7f7f7f;
        }
        &:hover {
          background-color: #1a1a1a;
        }
      }
    }
  }
  &__mob {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 16px;
    top: 13px;
    z-index: 2;
    &_inner {
      width: 37px;
      height: 26px;
      border: none;
      background-color: transparent;
      background-image: url('../img/icons/menu_close-ex.svg');
      background-repeat: no-repeat;
      background-size: contain;
      outline: none;
      &.folded {
        background-size: cover;
        background-image: url('../img/icons/menu-ex.svg');
      }
    }
  }
}

// навигация

.header__nav {
  margin-bottom: 20px;

  // верхнее меню
  & .nav__top {
    &_wrapper {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: flex-end;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 0;
      padding-right: 0;
    }
    &_item {
      color: #000;
      text-decoration: underline;
      margin-left: 18px;
      transition: color ease-in-out 0.2s;
      &:first-child {
        margin-left: 0;
      }
      &:visited {
        color: #000;
      }
      &:active {
        color: #b73439;
      }
      &:hover {
        color: #b73439;
      }
    }
  }

  // основное меню
  & .nav__main {
    text-align: center;
    background-color: #fff;
    border: 1px solid #e1e1e1;
    &_menu {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 0;
      padding-right: 0;
      margin-right: auto;
    }
    &_item {
      border-right: 1px solid #e1e1e1;
      & a {
        display: block;
        color: #000;
        background-color: transparent;
        text-decoration: none;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 45px;
        padding-right: 45px;
        transition: all ease-in-out 0.2s;
        &:visited {
          color: #000;
          background-color: transparent;
        }
        &:active {
          color: #fff;
          background-color: #2b2a29;
        }
        &:hover {
          color: #fff;
          background-color: #2b2a29;
        }
      }
      &.multi {
        position: relative;
        & span {
          position: relative;
          left: -10px;
        }
        & svg {
          position: absolute;
          right: 27px;
          top: 21px;
          width: 7px;
          fill: #000;
          transform: rotate(90deg);
          transition: all ease-in-out 0.2s;

          // IE fix
          height: 7px;
        }
        &:hover svg {
          transform: rotate(-90deg);
        }
        &:hover .nav__main_sub {
          display: block !important;
        }
        & > a:hover + svg {
          fill: #fff;
        }
      }
    }
    &_sub {
      position: absolute;
      width: 100%;
      background-color: #fff;
      border: 1px solid #e1e1e1;
      z-index: 1;
      display: none;
      & a {
        text-align: center;
        padding-left: 15px;
        padding-right: 15px;
        border-bottom: 1px solid #e1e1e1;
        &:last-child {
          border-bottom: none;
        }
      }
    }

    // поиск
    &_search {
      // position: relative;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: flex-end;
      max-width: 293px;
      padding-left: 0;
      padding-right: 0;
      @media (max-width: 1023px) {
        // padding-left: 10px;
        // padding-right: 10px;
      }
       & a {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 60px;
        height: 100%;
        border-left: 1px solid #e1e1e1;
        border-right: 1px solid #e1e1e1;
        transition: background ease-in-out 0.2s;

        // IE fix
        max-height: 48px;
        & svg {
          width: 20px;
          fill: #555555;
          transition: fill ease-in-out 0.2s;
          &.search-fold {
            transform: rotate(-90deg);
            position: relative;
            @media (max-width: 1023px) {
              left: 8px;
            }
            
          }
        }
        &:active {
          background-color: #fafafa;
          & svg {
            fill: #545454;
          }
        }
        &.active {
          background-color: #fafafa;
          & svg {
            fill: #545454;
          }
        }
        &:hover {
          background-color: #fafafa;
          & svg {
            fill: #545454;
          }
        }
      }
      &-wrapper {
        // position: relative;
        position: absolute;
        top: 0;
        left: 0;
        order: 1;
        display: none;
      }
      &-button {
        order: 2;
      }
    }

    // кнопка заказа
    &_order {
      position: relative;
      padding-right: 0;
      padding-left: 0;
      max-width: 155px;
      & a {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
        font-family: 'robotoslab';
        color: #fff;
        text-decoration: none;
        background-color: #b73439;
        transition: background ease-in-out 0.2s;
        &:visited {

        }
        &:active {
          background-color: #a73449;
        }
        &:hover {
          background-color: #a73449;
        }
      }
    }

    // мобильное меню
    &-mob {
      background-color: #fff;
      border: 1px solid #e1e1e1;
      border-top: none;
      &_menu {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        padding-left: 0;
        padding-right: 0;
        margin-right: auto;
      }
      &_item {
        width: 100%;
        & a {
          display: block;
          color: #000;
          background-color: transparent;
          text-decoration: none;
          padding-top: 12px;
          padding-bottom: 12px;
          padding-left: 45px;
          padding-right: 45px;
          transition: all ease-in-out 0.2s;
          &:visited {
            color: #000;
            background-color: transparent;
          }
          &:active {
            color: #fff;
            background-color: #2b2a29;
          }
          &:hover {
            color: #fff;
            background-color: #2b2a29;
          }
        }
        &.multi {
          position: relative;
          & span {
            position: relative;
          }
          & .fire-sub {
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 0;
            top: 0;
            width: 45px;
            height: 45px;
            cursor: pointer;
            z-index: 2;
            &.rotated {
              transform: rotate(180deg);
            }
          }
          & svg {
            
            width: 14px;
            fill: #000;
            transform: rotate(90deg);
            transition: all ease-in-out 0.2s;

            // IE fix
            height: 14px;
          }
          &:hover svg {
            fill: #fff;
          }
          & > .fire-sub:hover svg {
            fill: #000;
          }
        }
      }
      &_sub {
        width: 100%;
        background-color: #fff;
        border: 1px solid #e1e1e1;
        border-left: none;
        border-right: none;
        z-index: 1;
        &.folded {
          display: none;
        }
        & a {
          text-align: center;
          padding-left: 15px;
          padding-right: 15px;
          border-bottom: 1px solid #e1e1e1;
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}

// навигация подсайта

.header__nav-ex {
  margin-bottom: 20px;

  // меню подсайта
  & .nav-ex__main {
    text-align: center;
    background-color: #fff;
    border: 1px solid #e1e1e1;
    &_menu {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 0;
      padding-right: 0;
      margin-right: auto;
    }
    &_item {
      border-right: 1px solid #e1e1e1;
      & a {
        display: block;
        color: #000;
        background-color: transparent;
        text-decoration: none;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 45px;
        padding-right: 45px;
        transition: all ease-in-out 0.2s;
        &:visited {
          color: #000;
          background-color: transparent;
        }
        &:active {
          color: #fff;
          background-color: #2b2a29;
        }
        &:hover {
          color: #fff;
          background-color: #2b2a29;
        }
      }
    }
    &_sub {
      position: absolute;
      width: 100%;
      background-color: #fff;
      border: 1px solid #e1e1e1;
      z-index: 1;
      display: none;
      & a {
        text-align: center;
        padding-left: 15px;
        padding-right: 15px;
        border-bottom: 1px solid #e1e1e1;
        &:last-child {
          border-bottom: none;
        }
      }
    }

    // мобильное меню подсайта
    &-mob {
      position: absolute;
      top: 50px;
      left: 0;
      background-color: #2b2a29;
      border: 1px solid #e1e1e1;
      border-top: none;
      z-index: 3;
      &_menu {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        padding-left: 0;
        padding-right: 0;
        margin-right: auto;
      }
      &_item {
        width: 100%;
        & a {
          display: block;
          color: #fff;
          background-color: transparent;
          text-decoration: none;
          padding-top: 12px;
          padding-bottom: 12px;
          padding-left: 40px;
          padding-right: 40px;
          transition: all ease-in-out 0.2s;
          &:visited {
            color: #fff;
            background-color: transparent;
          }
          &:active {
            color: #fff;
            background-color: #a7a7a7;
          }
          &:hover {
            color: #fff;
            background-color: #a7a7a7;
          }
        }
      }
    }
  }
}

// основная сетка страницы

.main-content > .container {
  padding-left: 0;
  padding-right: 0;
}

.left {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.center {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.right {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

// контент

.content {
  &-simple {
    background-color: #fff;
    border: 1px solid #e1e1e1;
    padding-top: 23px;
    padding-bottom: 14px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 20px;
    & h1, & .h1 {
      border-bottom: 1px solid #e1e1e1;
      padding-bottom: 7px;
    }
  }
}

// поставщики

.partners {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 23px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  & h1, & .h1 {
    font-family: 'robotoslab';
    font-weight: 700;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 7px;
    margin-left: 16px;
    margin-right: 16px;
  }
  &__wrapper {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  &__item {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
    color: #000;
    text-decoration: none;
    transition: background ease-out 0.1s;
    &:hover {
      background-color: #e4f2ec;
    }
    &_logo {
      position: relative;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 36px;
      border: 1px solid #e1e1e1;
      margin-right: 14px;
      & img {
        width: 80%;
      }
    }
    &_caption {
      font-size: 13px;
      & .title {
        font-weight: 700;
      }
      & .location {
        
      }
    }
  }
}

// блок новостей/статей

.news-list, .article-list {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 23px;
  padding-bottom: 14px;
  margin-bottom: 20px;
  & h1, & .h1 {
    font-family: 'robotoslab';
    font-weight: 700;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 7px;
    margin-left: 16px;
    margin-right: 16px;
  }

  // плитка
  &__wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }
  &__item {
    position: relative;
    margin-bottom: 25px;
    & .time {
      font-size: 13px;
      color: #5a5a5a;
      line-height: 1.7;
      & .today {
        font-weight: 700;
        color: #a73439;
      }
    }
    & .img {
      position: relative;
      width: 100%;
      height: 165px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      margin-bottom: 20px;
      & .flag-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: flex-end;
      }
      & .flag {
        font-size: 13px;
        color: #fff;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 9px;
        padding-right: 9px;
        border-bottom-left-radius: 3px;
        &.market {
          background-color: rgba(30,113,186,0.8);
        }
        &.partner {
          background-color: rgba(54,175,115,0.8);
        }
        &.project {
          background-color: rgba(179,77,84,0.8);
        }
      }
    }
    & h2 {
      font-family: 'robotoslab';
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 0;
    }
    & .content-preview {
      font-size: 13px;
      margin-bottom: 10px;
    }
    & a {
      font-size: 13px;
      text-decoration: underline;
      &.full {
        font-weight: 700;
        color: #a73439;
      }
      &.company {
        font-style: italic;
        color: #000;
        text-decoration: underline;
      }
      &.h2 {
        font-size: 16px;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}

// фильтр новостей
.news-list {
  
  &__filter {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 7px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 12px;
    & h1, & .h1 {
      font-family: 'robotoslab';
      font-weight: 700;
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 5px;
      margin-left: 0;
      margin-right: 16px;
    }
    &_wrapper {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      & a {
        margin-right: 8px;
        &:last-child {
          margin-left: auto;
          margin-right: 0;
        }
      }
    }
  }

  &__switch {
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 7px;
    margin-left: 16px;
    margin-right: 16px;
    & .h1, & h1 {
      border-bottom: none;
    }
    & a {
      color: #80807f;
      margin-right: 15px;
      transition: color ease-in-out 0.2s;
      &:visited {
        color: #80807f;
      }
      &:active {
        color: #a73439;
      }
      &:hover {
        color: #a73439;
      }
      &:last-child {
        margin-right: 0;
      }
      &.active {
        color: #000;
        text-decoration: none;
        cursor: not-allowed;
        &:visited {
          color: #000;
        }
        &:active {
          color: #000;
        }
        &:hover {
          color: #000;
        }
      }
    }    
  }
}
.news-list-full--news {
  .news-list__switch {
    display: flex;
    justify-content: space-between;
    .selects-wrap {
      display: flex;
      .select2 {
        margin-left: 10px;
      }
    }
  }
}

//одна новость
.news-item--typical {
  .news-title {
    display: flex;
    justify-content: space-between;
    & h1, & .h1 {
      flex-grow: 5;
      .tag-wrap {
        display: inline-block;
        span {
          position: relative;
          font-size: 13px;
          color: #5a5a5a;
          font-weight: 300;
          position: relative;
          padding-left: 20px;
        }
        span::before {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          left: 7px;
          top: 5px;
        }
        .market::before {
          background: rgba(30, 113, 186, 1.0);
        }
        .partner::before {
          background: rgba(54, 175, 115, 1.0);
        }
        .project::before {
          background: rgba(179, 77, 84, 1.0);
        }
      }
    }
    & .news-date {
      flex-grow: 1;
      text-align: right;
    }
  }
}

// 404

.not-found {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 23px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 14px;
  margin-bottom: 20px;
}

// блок заявок
.order-list {
  font-size: 13px;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 23px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
  & h1, & .h1 {
    font-family: 'robotoslab';
    font-weight: 700;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 7px;
  }

  // превью заявки
  &__counter {
    &_value {
      font-size: 16px;
      font-weight: 700;
    }
  }
  &__item {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 94%;
    color: #000;
    text-decoration: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 20px;
    // margin-left: 10px;
    // margin-right: 10px;
    transition: all ease-in-out 0.2s;
    &:hover {
      box-shadow: 0 0 2px 2px rgba(0,0,0,0.1);
    }
    
    // статус бар
    &_top {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding-top: 10px;
      padding-bottom: 8px;
      padding-left: 12px;
      padding-right: 12px;
    }

    // тело заявки
    &_main {
      padding-top: 24px;
      border-left: 1px solid #e1e1e1;
      border-right: 1px solid #e1e1e1;
      flex-grow: 1;
      & h2 {
        font-family: 'robotoslab';
        font-weight: 700;
        line-height: 1.3;
        letter-spacing: 0;
        padding-left: 12px;
        padding-right: 12px;
      }
      & .content-preview {
        padding-bottom: 15px;
        padding-left: 12px;
        padding-right: 12px;
      }
    }
    &_status {
      color: #fff;
      line-height: 13px;
      border-radius: 15px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 12px;
      padding-right: 12px;
    }
    &_time {
      color: #606060;
      padding-left: 12px;
      padding-right: 12px;
      margin-bottom: 10px;
    }

    // геолокация
    &_geo {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border: 1px solid #e1e1e1;
      padding-top: 10px;
      padding-bottom: 15px;
      padding-left: 12px;
      padding-right: 12px;
      & svg {
        width: 18px;
        height: 18px;
        margin-right: 12px;
      }
    }

    // открытая
    &.opened {
      & .order-list__item_top {
        background-color: #d2efe2;
      }
      & .order-list__item_status {
        background-color: #22b573;
      }
    }

    // закрытая
    &.closed {
      cursor: not-allowed;
      & .order-list__item_top {
        background-color: #dad5d5;
      }
      & .order-list__item_status {
        background-color: #7f7f7f;
      }
      &:hover {
        box-shadow: none;
      }
    }
  }
}




// галерея

.gallery {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 23px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
  &__item {
    position: relative;
    margin-bottom: 20px;
    outline: none;
    &_inner {
      position: relative;
      width: 100%;
      min-height: 277px;
      & .img {
        width: 100%;
        height: 100%;
        min-height: 277px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }
      & .hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(179, 77, 84, 0.3);
        z-index: 1;
        opacity: 0;
        transition: opacity ease-in-out 0.2s;
        & svg {
          position: absolute;
          top: 13px;
          right: 23px;
          width: 28px;
          height: 28px;
          fill: #fff;
        }
      }
    }
    &:hover .hover {
      opacity: 1;
    }
  }

}

.mfp-close__gallery {
  top: 50px !important;
  & svg {
    fill: #fff !important;
  }
}

img.mfp-img {
  max-height: 90vh !important;
}

.mfp-title {
  padding-right: 0;
}

.mfp-counter {
  top: -25px;
  right: 10px;
  color: #fff;
  font-size: 16px;
  // display: none;
}

.mfp-pager {
  position: relative;
}

.mfp-pager .dots {
  position: relative;
  justify-content: space-between;
  height: 82px;
}

.mfp-pager .dots li {
  border: 1px solid transparent;
}

.mfp-pager .dots li.active {
  border: 1px solid #e1e1e1;
}

.mfp-pager .dots button {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  border: none;
  background-color: transparent;
  
}

.mfp-pager .dots img {
  height: 80px;
}

// карточка товара

.item-list {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 23px;
  padding-bottom: 50px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;

  // заголовок и фильтр
  &__top {
    position: relative;
    margin-bottom: 20px;
    &_stats {
      color: #7f7f7f;
    }
    &_sort {
      top: 0;
      right: 0;
    }
  }

  // таблица выдачи
  &__main {
    display: table;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 20px;
    &_head {
      display: table-header-group;
    }
    &_results {
      display: table-row-group;
    }
    &_cell {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      border: 1px solid #e1e1e1;
      padding-top: 13px;
      padding-bottom: 12px;
      padding-left: 5px; 
      padding-right: 5px;
      &.head {
        font-weight: 700;
        background-color: #fafafa;
        padding-top: 4px;
        padding-bottom: 4px;
      }
      &.phone {
        min-width: 143px;
        font-weight: 700;
      }
      &.price {
        position: relative;
        min-width: 96px;
        & svg {
          position: relative;
          top: -5px;
          width: 15px;
          height: 15px;
          fill: #4e4d4f;
        }
      }
      & input:focus + .item-list__price_tooltip.units {
        opacity: 1;
        z-index: 1;
      }
    }
    &_row {
      display: table-row;
      &.blank_row {
        display: none;
      }
      & a.expand {
        position: absolute;
        top: 10px;
        right: 10px;
        display: block;
        width: 30px;
        height: 30px;
        display: none;
        transition: all ease-in-out 0.2s;
        & svg {
          fill: #e1e1e1;
        }
        &.active {
          & svg {
            transform: rotate(180deg);
          }
        }
      }
      &.mode-color {
        & .item-list__main_cell {
          background-color: #fdfae9;
        }
      }
      &.mode-bold {
        & .item-list__main_cell {
          font-weight: 700;
        }
      }
    }
  }
  &__phone.holder {
    font-weight: 400;
    cursor: pointer;
    color: #a73439;
    text-decoration: underline;
  }
  &__price {
    text-decoration: underline;
    cursor: pointer;
    z-index: 1;
    &_tooltip {
      position: absolute;
      top: 2px;
      left: -100px;
      font-size: 12px;
      white-space: nowrap;
      color: #fff;
      background-color: rgba(0,0,0,0.6);
      padding-top: 3px;
      padding-bottom: 2px;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 5px;
      border-bottom-right-radius: 0;
      opacity: 0;
      z-index: -1;
      transition: all ease-in-out 0.2s;
      &.units {
        top: -15px;
        left: -130px
      }
    }
    &:hover + .item-list__price_tooltip {
      opacity: 1;
      z-index: 1;
    }
  }
  
}

// превью товара

.item-card {
  position: relative;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 16px;
  padding-bottom: 13px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;

  & .img {
    min-height: 226px;
    background-repeat: no-repeat;
    background-size: cover; 
    background-position: center center;
    margin-bottom: 16px;
  } 
  & .stats {
    margin-bottom: 20px;
    & h2 {
      border-bottom: 1px solid #e1e1e1;
      padding-bottom: 7px;
      margin-bottom: 9px;
    }
    & .item-card__row {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid #e1e1e1;
      padding-bottom: 7px;
      margin-bottom: 9px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  & .add {
    text-align: center;
    width: 100%;
  }
}

// размеры товара

.size-list {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 23px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
  &__group {
    & h3 {
      border-bottom: 1px solid #e1e1e1;
      padding-bottom: 7px;
      margin-bottom: 0;
    }
    &_wrapper {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-wrap: wrap;
      padding-top: 15px;
      padding-bottom: 20px;
      margin-left: -5px;
      margin-right: -5px;

    }
    &_item {
      color: #000;
      text-decoration: none;
      background-color: #fafafa;
      border: 1px solid #e1e1e1;
      border-radius: 3px;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 10px;
      margin-left: 5px;   
      margin-right: 5px;
      transition: background ease-out 0.2s;
      &:hover {
        color: #000;
        background-color: #e4f2ec;
      }
    }
  }
}

// фильтр простой

.filter-simple {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 23px;
  padding-bottom: 22px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
  & h1, & .h1 {
    margin-bottom: 0;
  }
  &__group {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    & a {
      display: block;
      min-width: 85px;
      font-size: 15px;
      line-height: 15px;
      text-align: center;
      color: #7f7f7f;
      background-color: #f0f0f0;
      border-radius: 3px;
      text-decoration: none;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 14px;
      padding-right: 14px;
      margin-left: 10px;
      transition: background ease-in-out 0.2s;
      &:first-child {
        margin-left: 0;
      }
      &:hover {
        background-color: #e8d9d5;
      }
      &.active {
        color: #fff;
        background-color: #b73439;
        cursor: not-allowed;
      }
    }
  }
}

// список товаров
.board-list {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 23px;
  padding-bottom: 22px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
  &__switch {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    margin-bottom: 20px;
    & a {
      color: #000;
      background-color: #fafafa;
      text-decoration: none;
      border-top: 1px solid #e1e1e1;
      border-bottom: 1px solid #e1e1e1;
      padding-top: 2px;
      padding-bottom: 2px;
      padding-left: 7px;
      padding-right: 7px;
      &:first-child {
        border-left: 1px solid #e1e1e1;
        border-right: 1px solid #e1e1e1;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-right: 1px solid #e1e1e1;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      &.active {
        background-color: #e5f3ed;
      }
    }
  }
  &__main {
    display: table;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 20px;
    &_head {
      display: table-header-group;
    }
    &_results {
      display: table-row-group;
    }
    &_cell {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      border: 1px solid #e1e1e1;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 5px; 
      padding-right: 5px;
      &.head {
        font-weight: 700;
        background-color: #fafafa;
        padding-top: 4px;
        padding-bottom: 4px;
      }
      &.filter {
        position: relative;
      }
      &.stats {
        text-align: left;
        background-color: transparent;

        & .block-with-text {
          overflow: hidden;
          font-size: 14px;
          letter-spacing: 0;
          width: 80px;
          position: relative; 
          line-height: 1.2em;
          max-height: 2.3em; 
          margin-right: -1em;
          padding-right: 1em;
          cursor: pointer;
          transition: color ease-in-out 0.1s;

          &:before {
            content: '...';
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 1;
          }
          &:after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            width: 1em;
            height: 1em;
            margin-top: 0.2em;
            background: white;
          }

          &:hover {
            color: #a73439;
          }

          &.active {
            overflow: visible;
            max-height: none;
            &:before {
              content: none;
            }
            &:after {
              background-color: transparent;
            }
          }

        }

      }
      &.price {
        position: relative;
        min-width: 96px;
        & svg {
          position: relative;
          top: -5px;
          width: 15px;
          height: 15px;
          fill: #4e4d4f;
        }
      }
      &.provider {
        text-align: left;
      }
      &.order {
        white-space: nowrap;
      }
    }
    &_row {
      position: relative;
      display: table-row;
      & a.expand {
        position: absolute;
        bottom: 10px;
        right: 10px;
        display: block;
        width: 30px;
        height: 30px;
        display: none;
        transition: all ease-in-out 0.2s;
        & svg {
          fill: #e1e1e1;
        }
        &.active {
          & svg {
            transform: rotate(180deg);
          }
        }
      }
      &.mode-color {
        & .board-list__main_cell {
          background-color: #fdfae9;
          position: relative;
          background-clip: padding-box;
        }
      }
      &.mode-bold {
        & .board-list__main_cell {
          font-weight: 700;
        }
      }
    }
  }
  &__reset {
    position: relative;
    font-size: 13px;
    color: #5a5a5a;
    text-decoration: none;
    padding-left: 15px;
    white-space: nowrap;
    & svg {
      position: absolute;
      top: 3px;
      left: 0;
      width: 9px;
      height: 9px;
      fill: #5a5a5a;
    }
  }
  &__provider {
    &_name {
      font-weight: 700;
      color: #000;
    }
  }
  &__phone_wrapper {
    white-space: nowrap;
  }
  &__phone.holder {
    font-weight: 400;
    cursor: pointer;
    color: #a73439;
    text-decoration: underline;
  }

  // [все]
  &-all {
    
  }
  // [группировка]
  &-group {
    & .board-list__main_cell.stats a {
      color: #000;
    }
    & .boad-list__price.min {
      font-weight: 700;
    }
    & .board-list__main_cell.provider {
      text-align: center;
    }
    & .board-list__main_cell.provider a {
      color: #000;
    }
    & .board-list__main_cell.add a {
      color: #fff;
      text-decoration: none;
      white-space: nowrap;
      background-color: #a73439;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 5px;
      padding-right: 5px;
      border-radius: 3px;
    }
  }
}

// визитка поставщика
.promo {
  position: relative;
  height: 365px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -10% 40%;
  margin-bottom: 20px;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
  &__scale {
    height: 365px;
    &:before {
      content: '';
      position: absolute;
      height: 100%;
      top: 0;
      left: -34%;
      width: 100%;
      height: 100%;
      background-color: #fff;
      transform: rotate(-70deg);
      z-index: 1;
    }
  }
  &__wrapper {
    position: relative;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 310px;
    height: 100%;
    background-color: #fff;
    padding-top: 45px;
    padding-bottom: 65px;
    padding-left: 25px;
    padding-right: 18px;
    z-index: 2;
  }
  &__logo {
    width: 265px;
    // max-height: 50px;
    margin-bottom: auto;
    & img {
      max-width: 100%;
    }
  }
  &__adress {
    font-size: 16px;
    margin-bottom: 25px;
    // IE fix
    align-self: stretch;
  }
  &__phone {
    font-size: 25px;
    font-weight: 700;
    color: #000;
    text-decoration: none;
    margin-bottom: 15px;
    letter-spacing: 0;
    &:hover {
      color: #000;
      text-decoration: none;
    }
  }
}

.promo-inner {
  position: relative;
  height: 135px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -10% 40%;
  margin-bottom: 20px;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
  &__scale {
    height: 135px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 16%;
      width: 300px;
      height: 100%;
      background-color: #fff;
      -webkit-transform: rotate(-70deg);
      transform: rotate(-70deg);
      z-index: 1;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 8%;
      width: 300px;
      height: 100%;
      background-color: #fff;
      -webkit-transform: rotate(-70deg);
      transform: rotate(-70deg);
      z-index: 1;
    }
  }
  &__wrapper {
    position: relative;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 18px;
    z-index: 2;
  }
  &__logo {
    align-self: flex-start;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 265px;
    height: 100%;
    margin-bottom: auto;
    & img {
      max-width: 100%;
    }
  }
  &__phone {
    font-size: 25px;
    font-weight: 700;
    color: #000;
    text-decoration: none;
    margin-bottom: 15px;
    letter-spacing: 0;
    &:hover {
      color: #000;
      text-decoration: none;
    }
  }
}

.provider-list__modal-link {

  margin-bottom: 20px;

}

// карточка поставщика
.provider-card {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
  // информация
  &__top {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  &__logo {
    position: relative;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 172px;
    height: 105px;
    border: 1px solid #e1e1e1;
    margin-right: 25px;
    & img {
      width: 70%;
    }
  }
  &__company {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__city {
    font-size: 16px;
  }
  &__contacts {
    font-size: 16px;
    font-weight: 700;
    margin-top: auto;
  }
  &__phone {
    &.holder {
      font-weight: 400;
      cursor: pointer;
      color: #a73439;
      text-decoration: underline;
    }
  }
  &__more {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-left: auto;
  }
  &__counter {
    font-size: 16px;
    color: #a3a3a3;
  }
  &__price {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  &__main {
    display: table;
    border-collapse: collapse;
    width: 100%;
    &_row {
      display: table-row;
    }
    &_cell {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      border: 1px solid #e1e1e1;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 5px; 
      padding-right: 5px;
      &.filter {
        position: relative;
      }
      &.stats {
        text-align: left;
        padding-top: 10px;
        padding-bottom: 10px;
      }
      &.price {
        position: relative;
        min-width: 96px;
        & svg {
          position: relative;
          top: -5px;
          width: 15px;
          height: 15px;
          fill: #4e4d4f;
        }
      }
      &.order {
        white-space: nowrap;
      }
    }
  }
}

// статистика

.stats {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
  &__item {
    margin-bottom: 15px;
    &.verify {
      position: relative;
      z-index: 1;
      & .stats__tooltip {
        position: absolute;
        top: 2px;
        left: -100px;
        font-size: 12px;
        white-space: nowrap;
        color: #fff;
        background-color: rgba(0,0,0,0.6);
        padding-top: 3px;
        padding-bottom: 2px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 5px;
        border-bottom-right-radius: 0;
        opacity: 0;
        z-index: -1;
        transition: all ease-in-out 0.2s;
      }
      & .stats__profile:hover + .stats__tooltip, .stats__contacts:hover + .stats__tooltip {
        opacity: 1;
        z-index: 1;
      }
      & svg {
        width: 16px;
        height: 16px;
        fill: #7e7d7f; 
      }
    }
  }
  &__profile, &__contacts {
    cursor: pointer;
    border-bottom: 1px solid #d0d0d0;
  }
  &__tooltip {
    font-weight: 400;
  }
  &__value {
    font-weight: 700;
    font-size: 16px;
  }
  &__social {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    margin-top: 10px;
    &_item {
      margin-right: 10px;
    }
    & svg {
      // width: 16px;
      height: 16px;
      fill: #2b2b2b;
      transition: all ease-in-out 0.2s;
    }
    &_item:hover {
      & svg {
        fill: #b73439;
      }
    }
  }
}

// каталог
.catalog {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  margin-bottom: 20px;
  &__col {
    padding-left: 0;
    padding-right: 0;
    border-right: 1px solid #e1e1e1;
    &:last-child {
      border-right: none;
    }
  }
  &__item {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-bottom: 1px solid #e1e1e1;
    padding-top: 45px;
    padding-bottom: 40px;
    &:last-child {
      border-bottom: none;
    }
    & h2 {
      padding-left: 40px;
      padding-right: 40px;
    }
    & a {
      font-size: 16px;
      color: #000;
      text-decoration: none;
      padding-left: 50px;
      padding-right: 40px;
      transition: background ease-out 0.1s; 
      &:before {
        content: '-';
        position: relative;
        left: -5px;
      }
      &:hover {
        background-color: #e4f2ec;
      }
    }
  }
}

// карта
.map {
  margin-bottom: 20px;
}

// футер

.footer {

  // футер
  &__main {
    color: #fff;
    padding-top: 29px;
    padding-bottom: 18px;
    background-color: #2b2a29;
    background-image: url('../img/multiples/pointless.svg');
    background-repeat: no-repeat;
    background-size: 55%;
    background-position: 45% 5%;
    & a {
      color: #fff;
      text-decoration: none;
    }
  }

  // нижний бар
  &__sub {
    color: #a7a7a7;
    background-color: #000;
    & .webmotor {
      text-align: right;
      & a, span {
        color: #a7a7a7;
      }
    }
  }

  // лого и информация
  &__copyright {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    &_logo {
      position: relative;
      max-width: 185px;
      margin-bottom: 25px;
      & svg {
        width: 100%;

        // IE fix
        height: 100%;
        max-height: 91px;
      }
    }
    &_caption {

      // IE fix
      align-self: stretch;
      & p {
        margin-bottom: 0;
        line-height: 2;        
      }
    }
  }

  // меню
  &__menu {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;

    // столбец меню
    &_group {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      & a:visited:not(.btn), .a:visited:not(.btn), .content a:visited:not(.btn), content .a:visited:not(.btn) {
        color: #fff;
      }

      // пункт категории
      &-heading {
        font-family: 'robotoslab';
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 33px;
      }

      // основные пункты
      &-item {
        line-height: 2;
      }
    }
  }

  // контакты
  &__contacts {
    text-align: right;

    // социальные сети
    &_social {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: flex-end;
      padding-top: 4px;
      margin-bottom: 42px;
      & a {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      & svg {
        height: 15px;
        fill: #fff;
      }
      &-item {
        margin-left: 16px;
        &:first-child {
          margin-left: 0;
        }
      }
    }

    // телефоны
    &_phone {
      &-item {
        &:first-child {
          margin-bottom: 15px;
        }
      }
      & a {
        font-family: 'robotoslab';
        font-size: 18px;
      }
    }
  }
}

// футер подсайта

.footer-ex {

  // футер
  &__main {
    color: #fff;
    padding-top: 29px;
    padding-bottom: 18px;
    background-color: #2b2a29;
    background-image: url('../img/multiples/pointless.svg');
    background-repeat: no-repeat;
    background-size: 55%;
    background-position: 45% 5%;
    & a {
      min-width: 86px;
      font-size: 14px;
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      margin-right: 70px;
      &:hover {
        color: #a73439;
      }
    }
  }

  // нижний бар
  &__sub {
    color: #a7a7a7;
    background-color: #000;
    & .warning {
      text-align: right;
    }
    & .policy {
      & a {
        color: #a7a7a7;
      }
    }
    & .webmotor {
      text-align: right;
      & a, span {
        color: #a7a7a7;
      }
    }
  }

  // лого и информация
  &__copyright {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    &_logo {
      position: relative;
      max-width: 185px;
      margin-bottom: 25px;
      & svg {
        width: 100%;

        // IE fix
        height: 100%;
        max-height: 23px;
      }
    }
  }

  // меню
  &__menu {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

/**
*/


.order-item {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding: 0;
  margin-bottom: 20px;
  &__left-col {
    padding: 23px 16px;
    & a.h2 {
      display: inline-block;
      text-decoration: none;
      margin-bottom: 10px;
    }
  }
  &__right-col {
    border-left: 1px solid rgb(225, 225, 225);
    background-color: #e8f7f0;    
    flex-direction: column;
    justify-content: space-between;
  }
}

.order-item__info-wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 50px 16px 23px;
  &__info-item {
    max-width: 50%;
    flex-basis: 50%;
    margin-bottom: 10px;
    b {
      display: inline-block;
      font-size: 16px;
    }
  }
}

.location-info-wrap {
  border-top: 1px solid rgb(225, 225, 225);
  padding: 18px 14px;
  .location-info {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: flex-start;
    .icon {
      position: relative;
      top: -5px;
      width: 20px;
      height: 30px;
      fill: #555;
      margin-right: 7px;
    }
  }
}

.order-item__closed {
  .h2 {
    color: #7f7f7f;
  }
  .order-item__right-col {
    background: #fafafa;
  }
  .order-item__info-wrap__info-item b {
    color: #7f7f7f;
  }
  .btn--primary {
    background: #7f7f7f;
    border-color: #7f7f7f;
    &.disabled {
      cursor: not-allowed;
    }
  }
}
.pagination-wrapper, 
.content .pagination-wrapper {
  width: 100%;
  text-align: right !important;

}

/*fixed merge*/

.sidebar-checkbox-wrap {
  display: flex;
  width: 100%;
  margin-bottom: 7px;
  padding-left: 40px;
  transition: background ease-in 0.1s;
  &:hover {
    background-color: #e4f2ec;
  }
  .site-form__label-checkbox {
    width: 16px;
    height: 16px;
    min-width: 16px;
  }
  .site-form__icon-checked {
    fill: #19c31e;
  }
  .site-form-checkbox-text {
    font-size: 14px;
    color: #000;
    font-weight: 400;
    cursor: pointer;
  }
}

.order-execution-wrap {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 0px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
}
input[type="text"].table-input {
  width: 100%;
  max-width: 100%;
  background-color: #fafae6;
  border-color: #e6e6e6;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  height: 28px;
  padding-left: 5px;
  padding-right: 5px;
}
input[type="text"].table-input:focus {
  background-color: #fff;
}

.order-item__execution {  
  padding-top: 20px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
}

.order-item__welcome {  
  padding-top: 20px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
}

.item-list__relative-row {
  //position: relative;
}
.add-row-wrap {
  //position: absolute;
  top: -1px;
  bottom: 0;
  right: -55px;
  width: 55px;
  border: 0px solid #e1e1e1;
  border-width: 1px 1px 1px 0;
  background-color: #fff;
  padding: 20px 0 0 10px ;
  box-shadow: 2px 2px 2px #cdcdcd;
  .changeRow {
    text-decoration: none;
    margin-right: 2px;
    display: inline;
    cursor: pointer;
    span {
      display: none;
    }
  }
  .fa {
    color: #333;
    font-size: 20px;
  }
  .icon {
    width: 14px;
    height: 14px;
    fill: #333;
    &:hover {
      fill: #555;
    }  
  }
}
/* .add-row-wrap {
  display: none;
}
.item-list__main_row:hover {
  .add-row-wrap {
    display: block;
  }
} */

.hidden-block {
  display: none;
}

input[type="text"].price-input {
  max-width: 100px;
  width: 100px;
}

.order-card__order-info {
  position: relative;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 16px;
  padding-bottom: 13px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
}

dl.top-info {
  background: #e8f7f0;
  padding: 10px;
  dt {
    
  }
  dd {
    padding-left: 0;
    margin-left: 0;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
  }
}
dl.info { 
  padding: 10px; 
  dt {
    
  }
  dd {
    padding-left: 0;
    margin-left: 0;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
  }
}

.collapse-text  {
  .holder {
    text-decoration: underline;
    cursor: pointer;
  }
}

.files-wrap {
  padding-top: 8px; 
  .doc {
    display: flex;
    align-items: center;
    color: #000;
    font-size: 14px;
  }
  .icon {
    width: 24px;
    height: 33px;
    fill: #000;
    margin-right: 8px;
  }
}


h1 span.status,
.h1 span.status {
  display: inline-block;
  color: #fff;
  background-color: #22b573;
  border-radius: 15px;
  font-size: 13px;
  padding: 2px 6px 3px;
  position: relative;
  top: -3px;
  text-transform: lowercase;
  margin-left: 15px;
}

/*ymaps*/
.map-wrap {
  padding-top: 75px;
  padding-bottom: 45px;
} 
.map {
  height: 350px;
  background-position: 50% 50%;
  background-repeat: repeat;
}

.popover-wrapper {
  background: #fff;
  border: 1px solid @gray;
  padding: 10px;
  position: relative;
  p {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .popover-close {
    text-align: right;
    width: 20px;
    display: block;
    padding: 3px;
    position: absolute;
    right: 5px;
    top: 5px;
  }
  a {
    color: #1b7eb0;
    &:hover {
      text-decoration: none;
    }
  }
} 

.popover-close .icon {
  fill: #000;
  width: 14px;
  height: 14px;
}

.contacts-info {
  margin-bottom: 20px;
  p {
    margin-bottom: 10px;
    b {
      color: #000;
      font-weight: 500;
    }
  }
}
.popover-title.h5 {
  margin-bottom: 10px;
}

.presentation-block label.error, .footer-block label.error { color: #4e8d92; font-weight: 300; padding: 4px 22px; border-radius: 22px; margin-top: 5px; display: block; background: rgba(255, 255, 255, 0.8);  position: absolute;  top: 100%;  }
          .white-popup { position: relative;background: #FFF;padding: 20px;width: auto;max-width: 500px;margin: 20px auto; }


.mobile-banner-block .banner__side-long {
  display: none;
}


/*таблица*/
.item-list__main_cell .head {
  width: 100%;
  display: none;
}


//блок заявок на мобильном
.order-item-mobile {
  .order-list__item {    
    background-color: #fff;
    &:hover {
      box-shadow: 0 0 0px 0px rgba(0,0,0,0.1);
    }
  }
  .order-list__item .order-list__item_top {
    background-color: #d2efe2;
  }
  .btn {
    margin-left: 10px;
    margin-bottom: 10px;
  }
  & a.h2 {

    padding-left: 12px;
    padding-right: 12px;
    text-decoration: none;

  }

  & a.btn--small {
    font-family: 'proxima_nova';
    font-size: 14px;
    font-weight: 300;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 8px;
    padding-right: 8px;
    margin-left: auto;
    margin-bottom: 0;
    border: none;
    border-radius: 3px;
  }
}

/*список заявок на мобильном*/
.order-item__mobile-footer {
  display: none;
}
@media (max-width: 767px) {  
  .item-list__main_results {
    display: block;
  }  
  .item-list__main_cell {
    min-width: 100%;
    .head {
      display: inline-block;
      font-weight: 600;
      min-width: 50%;
    }
  }
  .order-item__right-col {
    order: 1;
    border-left: 0;
    border-radius: 0 0 10px 10px;
    .location-info-wrap {
      display: none;
    }
    .order-item__info-wrap {
      padding: 10px;
    }
  }
  .order-item__left-col {
    order: 2;
  }
  .order-item__mobile-footer {
    display: block;
    order: 3;
    background-color: #e8f7f0;
    border-radius: 10px 10px 0 0;
    .location-info-wrap {
      border: 0;
    }
  }
  .order-item__closed {
    .order-item__mobile-footer {
      background-color: #fafafa;
    }
    .btn {
      cursor: not-allowed;
    }
  }
  .right__item {
    .btn.btn--primary.add {
      display: none;
    }
  }
  .item-list__caption {
    .btn.btn--primary.add {
      display: inline-block;
    }
  }
  .add-row-wrap {
    top: auto;
    bottom: auto;
    right: auto;
    width: 100%;
    border: 0px solid #e1e1e1;
    border-width: 1px 1px 1px 1px;
    background-color: #fff;
    padding: 20px 10px 10px;
    box-shadow: 0px 0px 0px #cdcdcd;
    .icon {
      margin-right: 10px;
    }
    .changeRow {
      text-decoration: none;
      margin-right: 2px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      span {
        display: block;
      }
    }
  }
}

.order-item-mobile  .order-list__item.closed .order-list__item_top {
  background: #dad5d5;
}

.order-item-wrap .order-item-mobile {
  display: none;
}
.order-item-wrap .order-item {
  display: block;
}

.board-list__main_row {

  & ins {

    position: relative;
    & img {
      
      // max-width: 100%;
      // height: auto;

    }

  }

}

.mfp-bg {

  height: 100% !important;

}
.mfp-wrap {

 -webkit-overflow-scrolling: touch;

}
@media (max-width: 768px) {
  .order-item-wrap .order-item-mobile {
    display: block;
  }
  .order-item-wrap .order-item {
    display: none;
  }
}