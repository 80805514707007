// 1. ЦВЕТА
// --------------------------
@primary-color:                 #33abff; // основной цвет сайта
@primary-color-2:               #e70a2e; // второстепенный цвет сайта (опционально)

//== gray scale
@gray:                          #f6f6f6; // основной серый цвет (цвет контролов)
@gray--darken:                  #adadad; // более темный цвет (для рамок контролов)

//== заголовки
@heading-color:                 #222222; // цвета заголовков
@text-color:                    #424141; // цвет текстов по умолчанию

//== ссылки
@link-color:                    #a73439;
@link-hover:                    #9a1a06; // :hover
@link-active:                   #9a1a06; // :active
@link-visited:                  #8a009c; // :visited

//== контролы формы
@form-focus-control-color:      @primary-color; // :focus (не должен быть красным)
@form-error-border-color:       #d04035; // цвет рамки контрола с ошибкой
@form-error-background-color:   #f6dede; // цвет фона контрола с ошибкой



//== Параметры сетки
@screen-sm:                     768px;
@screen-md:                     1025px;
@screen-lg:                     1200px;

//== Отступ между колонок. Значение делится на половину слева и справа
@grid-gutter-width:             20px;



//== MASTER Z-INDEX
@z-index-header-modal-form:     3000;
@z-index-header--fixed:         2000;
@footer-z-index:                1900;



//== ШРИФТЫ
@main-font:                     "proxima_nova", Helvetica, Tahoma, Arial, sans-serif;
@mono-font:                     Menlo, Monaco, Consolas, "Courier New", monospace;
.rouble {
  font-family:'rouble';
  font-weight: normal;
  font-style: normal;
}
//== ШРИФТЫ размеры
@font-size:                     14px;
@line-height:                   1.5;



//== ТИПОГРАФИКА
@paragraph-margin-bottom:         20px; // отступ после тега <p>
