/**
*
* site custom css
*/
/* variables */
.rouble {
  font-family: 'rouble';
  font-weight: normal;
  font-style: normal;
}
/**
* Это основной стилевой файл
*/
html,
body {
  overflow-x: hidden;
}
body {
  width: 100%;
}
.header--fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
.header--fixed .header__main {
  padding-left: 0;
  padding-right: 0;
  border: none;
  border-bottom: 1px solid #e1e1e1;
}
.header__nav--fixed {
  position: fixed;
  top: 93px;
  left: 0;
  width: 100%;
  z-index: 999;
}
.header__nav--fixed .nav__main-mob {
  padding-left: 0;
  padding-right: 0;
  border: none !important;
  border-bottom: 1px solid #e1e1e1;
}
.header__nav-d--fixed {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  z-index: 999;
}
/**
* header mobile start
* -----------------------------------------------------------------------------
*/
.header-mobile {
  /**
  * локальные переменные
  * переназначайте их как угодно и где угодно
  */
  /* отступ элементов слева */
  /* отступ элементов справа */
  align-items: center;
  height: 70px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: none;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}
.header-mobile-menu-button-wrapper {
  display: none;
}
.header-mobile-menu-inner-wrapper {
  flex-direction: column;
  display: flex;
  height: 100%;
  padding-bottom: 20px;
  overflow-y: scroll;
}
.header-mobile-nav {
  margin-bottom: 30px;
}
.header-mobile-menu {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: calc(100vh - 70px);
  background-color: #f6f6f6;
}
.header-mobile-menu .btn {
  align-self: flex-start;
  min-height: 47px;
  margin-right: 10px;
  margin-left: 10px;
}
.header-mobile-content-wrapper {
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.header-mobile-logo-wrapper {
  height: 100%;
  margin-right: 30px;
}
.header-mobile-logo {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 54px;
}
.header-mobile__phone {
  position: relative;
  top: 2px;
  display: inline-block;
  color: #424141;
  text-decoration: none;
  line-height: 1;
}
.header-mobile__phone:active {
  color: #9a1a06;
}
.header-mobile-phone-wrapper {
  height: 100%;
}
.header-mobile-nav-list {
  display: block;
}
.header-mobile-nav-item {
  border-bottom: 1px solid #adadad;
}
.header-mobile-nav-item:last-child {
  border-bottom: 0;
}
.header-mobile-nav__link {
  display: block;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 24px;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
}
.header-mobile-nav__link.active {
  color: #9a1a06;
}
.header-mobile__menu-button {
  display: block;
  padding: 0;
  border: 0;
  position: relative;
  width: 70px;
  height: 70px;
  background-color: #f6f6f6;
  text-decoration: none;
}
.header-mobile__menu-button:focus {
  outline: 0;
}
.header-mobile__menu-button div {
  position: absolute;
  left: 50%;
  margin-top: 23px;
  margin-left: -15px;
  width: 30px;
  height: 2px;
  background-color: #424141;
  transition: 0.3s transform ease;
}
.header-mobile__menu-button div:nth-child(1) {
  top: 0;
}
.header-mobile__menu-button div:nth-child(2) {
  top: 11px;
}
.header-mobile__menu-button div:nth-child(3) {
  top: 21px;
}
.header-mobile__menu-button.active {
  background-color: #f6f6f6;
}
.header-mobile__menu-button.active div:nth-child(1) {
  transform: rotate(45deg);
  transition: 0.3s all ease;
  top: 10px;
}
.header-mobile__menu-button.active div:nth-child(2) {
  opacity: 0;
  transition: 0.3s opacity ease;
}
.header-mobile__menu-button.active div:nth-child(3) {
  transform: rotate(-45deg);
  transition: 0.3s all ease;
  top: 10px;
}
.header-mobile-menu-phone-wrapper {
  margin-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
}
.header-mobile-menu__phone {
  position: relative;
  top: 2px;
  display: inline-block;
  color: #424141;
  text-decoration: none;
  line-height: 1;
}
/*
@media (max-width:1024px) {
  body {
    padding-top: 70px;
  }
  .header {
    z-index: 100;
    height: 70px;
  }
  .header-mobile {
    display: flex;
  }
  .header-inner-wrapper {
    display: none;
  }
  .header-mobile-menu-button-wrapper {
    display: block;
  }
}
// header mobile close
*/
body {
  position: relative;
  background-color: #f0f0f0;
  letter-spacing: -0.8px;
  overflow-x: hidden;
}
.decoration {
  background-image: url('../img/multiples/pointless-body.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 700px;
  height: 700px;
  overflow: hidden;
  z-index: -1;
}
.decoration.right {
  position: absolute;
  display: block;
  top: 300px;
  right: -270px;
}
.decoration.left {
  position: absolute;
  display: block;
  bottom: 300px;
  left: -270px;
}
@media (max-width: 1199px) {
  .decoration {
    display: none !important;
  }
}
.multiple__order {
  position: fixed;
  top: 350px;
  right: -15px;
  width: 80px;
  height: 80px;
  z-index: 9;
}
.multiple__order svg {
  width: 80px;
  height: 80px;
}
@media (max-width: 1199px) {
  .multiple__order {
    display: none;
  }
}
.multiple__purchase {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 777;
}
.multiple__purchase a {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  font-family: 'robotoslab';
  color: #fff;
  text-decoration: none;
  background-color: #b73439;
  transition: background ease-in-out 0.2s;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.multiple__purchase a:visited {
  color: #fff;
  background-color: #b73439;
}
.multiple__purchase a:active {
  background-color: #a73449;
}
.multiple__purchase a:hover {
  background-color: #a73449;
}
@media (min-width: 769px) {
  .multiple__purchase {
    display: none;
  }
}
.banner__lg {
  width: 100%;
  padding-top: 25px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  margin-bottom: 10px;
}
.banner__lg img,
.banner__lg ins,
.banner__lg iframe,
.banner__lg canvas {
  display: block;
  width: 100% !important;
  height: auto !important;
  margin: 0 !important;
  padding: 0 !important;
  max-width: 100% !important;
  min-width: 100% !important;
}
.banner__sm {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 18px;
}
.banner__sm img {
  width: 100%;
  height: auto;
}
.banner__table {
  width: 100%;
}
.banner__table img {
  width: 100%;
  height: auto;
}
.banner__side-long {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}
.banner__side-long img {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: auto;
}
.banner__side-short {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}
.banner__side-short img {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: auto;
}
.banner__news {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 18px;
  padding-top: 14px;
}
.banner__news img {
  width: 100%;
  height: auto;
}
.banner__side-tiny {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}
.banner__side-tiny img {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: auto;
}
.banner__side-place {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 23px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.banner__side-place__wrapper {
  padding: 0 25px;
}
.banner__side-place__wrapper .banner {
  margin-bottom: 17px;
}
.right__item.sticky {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  width: 100%;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .right__item .banner__side-tiny {
    display: none;
  }
}
.header__main {
  background-color: #fff;
  border: 1px solid #e1e1e1;
}
.header__main_top {
  border-bottom: 1px solid #e1e1e1;
}
.header__main_contacts {
  position: relative;
  padding-top: 26px;
  padding-bottom: 18px;
}
.header__login {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0;
}
.header__login.misc {
  /* display: none; */
}
.header__login.misc a {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  height: 100%;
  color: #000;
  text-decoration: none;
  border-right: 1px solid #e1e1e1;
  padding-left: 37px;
  padding-right: 9px;
  transition: background ease-in-out 0.2s;
}
.header__login.misc a:visited {
  color: #000;
  text-decoration: none;
}
.header__login.misc a:active {
  color: #000;
  text-decoration: none;
  background-color: #fafafa;
}
.header__login.misc a:hover {
  color: #000;
  text-decoration: none;
  background-color: #fafafa;
}
.header__login.misc a svg {
  position: absolute;
  top: 14px;
  left: 11px;
  height: 15px;
  fill: #555555;
  width: 20px;
}
.header__login.user {
  display: none;
  position: relative;
}
.header__login.user > a {
  position: relative;
  height: 100%;
  padding-left: 37px;
  padding-right: 37px;
  white-space: nowrap;
  width: 100%;
  max-width: 270px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  color: #000;
  text-decoration: none;
  border-right: 1px solid #e1e1e1;
  padding-top: 10px;
  padding-bottom: 8px;
}
.header__login.user svg {
  position: absolute;
  width: 16px;
  fill: #000;
  transition: all ease-in-out 0.2s;
}
.header__login.user svg.user_icon {
  top: 13px;
  left: 10px;
}
.header__login.user svg.user_arrow {
  top: 14px;
  right: 13px;
}
.header__login.user a.account {
  transition: all ease-in-out 0.2s;
}
.header__login.user a.account:visited {
  color: #000;
  text-decoration: none;
}
.header__login.user a.account:active {
  color: #7f7f7f;
  text-decoration: none;
}
.header__login.user a.account:active svg {
  fill: #7f7f7f;
}
.header__login.user a.account:hover {
  color: #7f7f7f;
  text-decoration: none;
}
.header__login.user a.account:hover svg {
  fill: #7f7f7f;
}
.header__login.user a.account.active {
  color: #7f7f7f;
  text-decoration: none;
}
.header__login.user a.account.active svg {
  fill: #7f7f7f;
}
.header__login.user a.account.active svg.user_arrow {
  transform: rotate(180deg);
}
.header__login.user a.exit,
.header__login.user a.profile {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 35px;
  width: 100%;
  height: 45px;
  color: #7f7f7f;
  text-decoration: none;
  background-color: #fff;
  transition: background ease-in-out 0.2s;
}
.header__login.user a.exit svg,
.header__login.user a.profile svg {
  position: absolute;
  fill: #7f7f7f;
  top: 14px;
  left: 10px;
}
.header__login.user a.exit:hover,
.header__login.user a.profile:hover {
  background-color: #fafafa;
}
.header__login_dropdown {
  position: absolute;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  left: -1px;
  bottom: -92px;
  width: 100%;
  max-width: 271px;
  height: 92px;
  border: 1px solid #e1e1e1;
  background-color: #fff;
  z-index: 1;
  display: none;
}
.header__counter {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 8px;
}
.header__counter_value {
  font-family: 'robotoslab';
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
  color: #9a1a06;
}
.header__social {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0;
}
.header__social a {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-left: 1px solid #e1e1e1;
  padding-left: 16px;
  padding-right: 16px;
  transition: background ease-in-out 0.2s;
}
.header__social a:active {
  background-color: #fafafa;
}
.header__social a:hover {
  background-color: #fafafa;
}
.header__social a svg {
  width: 16px;
  height: 16px;
  fill: #6a6a6a;
}
.header__logo {
  position: relative;
}
.header__logo a {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: 54px;
}
.header__logo a svg {
  width: 100%;
}
.header__contacts {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header__contacts_item {
  text-align: right;
  padding-left: 40px;
}
.header__contacts_item:first-child {
  padding-left: 0;
}
.header__contacts_item a {
  position: relative;
  font-family: 'robotoslab';
  font-weight: 700;
  font-size: 20px;
  color: #000;
  text-decoration: none;
}
.header__contacts_item a:visited {
  color: #000;
  text-decoration: none;
}
.header__contacts_item a:active {
  color: #000;
  text-decoration: none;
}
.header__contacts_item a:hover {
  color: #000;
  text-decoration: none;
}
.header__contacts_item a svg {
  position: absolute;
  top: 8px;
  right: -20px;
  width: 15px;
}
.header__contacts_item.city a {
  position: relative;
  left: -20px;
}
.header__contacts_item.phone {
  letter-spacing: 0;
}
.header__mob {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 16px;
  top: 13px;
  z-index: 2;
}
.header__mob_inner {
  width: 42px;
  height: 30px;
  border: none;
  background-color: transparent;
  background-image: url('../img/icons/menu_close.svg');
  background-repeat: no-repeat;
  background-size: contain;
  outline: none;
}
.header__mob_inner.folded {
  background-size: cover;
  background-image: url('../img/icons/menu.svg');
}
.header__mob-search {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 16px;
  top: 9px;
  z-index: 2;
}
.header__mob-search_inner {
  width: 30px;
  height: 30px;
  border: none;
  background-color: transparent;
  background-image: url('../img/icons/right-arrow.svg');
  background-repeat: no-repeat;
  background-size: contain;
  outline: none;
}
.header__mob-search_inner.folded {
  background-image: url('../img/icons/search.svg');
}
.header-ex {
  margin-top: 30px;
  margin-bottom: 20px;
}
.header-ex__main {
  height: 50px;
  background-color: #2b2a29;
}
.header-ex__logo {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 580px) {
  .header-ex__logo {
    justify-content: center;
  }
}
.header-ex__logo a {
  max-height: 54px;
}
.header-ex__logo a svg {
  width: 100%;
  height: 100%;
}
.header-ex__login {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0;
  display: none;
}
.header-ex__login.misc {
  /* display: none; */
}
.header-ex__login.misc a {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  height: 100%;
  color: #fff;
  text-decoration: none;
  border-left: 1px solid #6e6e6e;
  padding-left: 37px;
  padding-right: 9px;
  transition: background ease-in-out 0.2s;
}
.header-ex__login.misc a:visited {
  color: #000;
  text-decoration: none;
}
.header-ex__login.misc a:active {
  color: #000;
  text-decoration: none;
  background-color: #fafafa;
}
.header-ex__login.misc a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1a1a1a;
}
.header-ex__login.misc a svg {
  position: absolute;
  top: 17px;
  left: 11px;
  height: 15px;
  fill: #e9e9e9;
  width: 20px;
}
.header-ex__login.user {
  display: none;
  position: relative;
}
.header-ex__login.user > a {
  position: relative;
  height: 100%;
  padding-left: 37px;
  padding-right: 37px;
  white-space: nowrap;
  width: 100%;
  max-width: 270px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  color: #fff;
  text-decoration: none;
  padding-top: 15px;
  padding-bottom: 8px;
}
.header-ex__login.user svg {
  position: absolute;
  width: 16px;
  fill: #e9e9e9;
  transition: all ease-in-out 0.2s;
}
.header-ex__login.user svg.user_icon {
  top: 17px;
  left: 10px;
}
.header-ex__login.user svg.user_arrow {
  top: 17px;
  right: 13px;
}
.header-ex__login.user a.account {
  transition: all ease-in-out 0.2s;
}
.header-ex__login.user a.account:visited {
  color: #000;
  text-decoration: none;
}
.header-ex__login.user a.account:active {
  color: #7f7f7f;
  text-decoration: none;
}
.header-ex__login.user a.account:active svg {
  fill: #7f7f7f;
}
.header-ex__login.user a.account:hover {
  color: #7f7f7f;
  text-decoration: none;
}
.header-ex__login.user a.account:hover svg {
  fill: #7f7f7f;
}
.header-ex__login.user a.account.active {
  color: #7f7f7f;
  text-decoration: none;
}
.header-ex__login.user a.account.active svg {
  fill: #7f7f7f;
}
.header-ex__login.user a.account.active svg.user_arrow {
  transform: rotate(180deg);
}
.header-ex__login.user a.exit {
  position: absolute;
  bottom: -46px;
  height: 46px;
  color: #7f7f7f;
  background-color: #2b2a29;
  border-top: 1px solid #7f7f7f;
  z-index: 1;
  transition: background ease-in-out 0.2s;
  z-index: 4;
  display: none;
}
.header-ex__login.user a.exit svg {
  position: absolute;
  top: 14px;
  left: 10px;
  fill: #7f7f7f;
}
.header-ex__login.user a.exit:hover {
  background-color: #1a1a1a;
}
.header-ex__mob {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 16px;
  top: 13px;
  z-index: 2;
}
.header-ex__mob_inner {
  width: 37px;
  height: 26px;
  border: none;
  background-color: transparent;
  background-image: url('../img/icons/menu_close-ex.svg');
  background-repeat: no-repeat;
  background-size: contain;
  outline: none;
}
.header-ex__mob_inner.folded {
  background-size: cover;
  background-image: url('../img/icons/menu-ex.svg');
}
.header__nav {
  margin-bottom: 20px;
}
.header__nav .nav__top_wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 0;
  padding-right: 0;
}
.header__nav .nav__top_item {
  color: #000;
  text-decoration: underline;
  margin-left: 18px;
  transition: color ease-in-out 0.2s;
}
.header__nav .nav__top_item:first-child {
  margin-left: 0;
}
.header__nav .nav__top_item:visited {
  color: #000;
}
.header__nav .nav__top_item:active {
  color: #b73439;
}
.header__nav .nav__top_item:hover {
  color: #b73439;
}
.header__nav .nav__main {
  text-align: center;
  background-color: #fff;
  border: 1px solid #e1e1e1;
}
.header__nav .nav__main_menu {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
  margin-right: auto;
}
.header__nav .nav__main_item {
  border-right: 1px solid #e1e1e1;
}
.header__nav .nav__main_item a {
  display: block;
  color: #000;
  background-color: transparent;
  text-decoration: none;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 45px;
  padding-right: 45px;
  transition: all ease-in-out 0.2s;
}
.header__nav .nav__main_item a:visited {
  color: #000;
  background-color: transparent;
}
.header__nav .nav__main_item a:active {
  color: #fff;
  background-color: #2b2a29;
}
.header__nav .nav__main_item a:hover {
  color: #fff;
  background-color: #2b2a29;
}
.header__nav .nav__main_item.multi {
  position: relative;
}
.header__nav .nav__main_item.multi span {
  position: relative;
  left: -10px;
}
.header__nav .nav__main_item.multi svg {
  position: absolute;
  right: 27px;
  top: 21px;
  width: 7px;
  fill: #000;
  transform: rotate(90deg);
  transition: all ease-in-out 0.2s;
  height: 7px;
}
.header__nav .nav__main_item.multi:hover svg {
  transform: rotate(-90deg);
}
.header__nav .nav__main_item.multi:hover .nav__main_sub {
  display: block !important;
}
.header__nav .nav__main_item.multi > a:hover + svg {
  fill: #fff;
}
.header__nav .nav__main_sub {
  position: absolute;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  z-index: 1;
  display: none;
}
.header__nav .nav__main_sub a {
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid #e1e1e1;
}
.header__nav .nav__main_sub a:last-child {
  border-bottom: none;
}
.header__nav .nav__main_search {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end;
  max-width: 293px;
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 1023px) {
}
.header__nav .nav__main_search a {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 60px;
  height: 100%;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  transition: background ease-in-out 0.2s;
  max-height: 48px;
}
.header__nav .nav__main_search a svg {
  width: 20px;
  fill: #555555;
  transition: fill ease-in-out 0.2s;
}
.header__nav .nav__main_search a svg.search-fold {
  transform: rotate(-90deg);
  position: relative;
}
@media (max-width: 1023px) {
  .header__nav .nav__main_search a svg.search-fold {
    left: 8px;
  }
}
.header__nav .nav__main_search a:active {
  background-color: #fafafa;
}
.header__nav .nav__main_search a:active svg {
  fill: #545454;
}
.header__nav .nav__main_search a.active {
  background-color: #fafafa;
}
.header__nav .nav__main_search a.active svg {
  fill: #545454;
}
.header__nav .nav__main_search a:hover {
  background-color: #fafafa;
}
.header__nav .nav__main_search a:hover svg {
  fill: #545454;
}
.header__nav .nav__main_search-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  order: 1;
  display: none;
}
.header__nav .nav__main_search-button {
  order: 2;
}
.header__nav .nav__main_order {
  position: relative;
  padding-right: 0;
  padding-left: 0;
  max-width: 155px;
}
.header__nav .nav__main_order a {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  font-family: 'robotoslab';
  color: #fff;
  text-decoration: none;
  background-color: #b73439;
  transition: background ease-in-out 0.2s;
}
.header__nav .nav__main_order a:active {
  background-color: #a73449;
}
.header__nav .nav__main_order a:hover {
  background-color: #a73449;
}
.header__nav .nav__main-mob {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-top: none;
}
.header__nav .nav__main-mob_menu {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-left: 0;
  padding-right: 0;
  margin-right: auto;
}
.header__nav .nav__main-mob_item {
  width: 100%;
}
.header__nav .nav__main-mob_item a {
  display: block;
  color: #000;
  background-color: transparent;
  text-decoration: none;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 45px;
  padding-right: 45px;
  transition: all ease-in-out 0.2s;
}
.header__nav .nav__main-mob_item a:visited {
  color: #000;
  background-color: transparent;
}
.header__nav .nav__main-mob_item a:active {
  color: #fff;
  background-color: #2b2a29;
}
.header__nav .nav__main-mob_item a:hover {
  color: #fff;
  background-color: #2b2a29;
}
.header__nav .nav__main-mob_item.multi {
  position: relative;
}
.header__nav .nav__main-mob_item.multi span {
  position: relative;
}
.header__nav .nav__main-mob_item.multi .fire-sub {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 45px;
  height: 45px;
  cursor: pointer;
  z-index: 2;
}
.header__nav .nav__main-mob_item.multi .fire-sub.rotated {
  transform: rotate(180deg);
}
.header__nav .nav__main-mob_item.multi svg {
  width: 14px;
  fill: #000;
  transform: rotate(90deg);
  transition: all ease-in-out 0.2s;
  height: 14px;
}
.header__nav .nav__main-mob_item.multi:hover svg {
  fill: #fff;
}
.header__nav .nav__main-mob_item.multi > .fire-sub:hover svg {
  fill: #000;
}
.header__nav .nav__main-mob_sub {
  width: 100%;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-left: none;
  border-right: none;
  z-index: 1;
}
.header__nav .nav__main-mob_sub.folded {
  display: none;
}
.header__nav .nav__main-mob_sub a {
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid #e1e1e1;
}
.header__nav .nav__main-mob_sub a:last-child {
  border-bottom: none;
}
.header__nav-ex {
  margin-bottom: 20px;
}
.header__nav-ex .nav-ex__main {
  text-align: center;
  background-color: #fff;
  border: 1px solid #e1e1e1;
}
.header__nav-ex .nav-ex__main_menu {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
  margin-right: auto;
}
.header__nav-ex .nav-ex__main_item {
  border-right: 1px solid #e1e1e1;
}
.header__nav-ex .nav-ex__main_item a {
  display: block;
  color: #000;
  background-color: transparent;
  text-decoration: none;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 45px;
  padding-right: 45px;
  transition: all ease-in-out 0.2s;
}
.header__nav-ex .nav-ex__main_item a:visited {
  color: #000;
  background-color: transparent;
}
.header__nav-ex .nav-ex__main_item a:active {
  color: #fff;
  background-color: #2b2a29;
}
.header__nav-ex .nav-ex__main_item a:hover {
  color: #fff;
  background-color: #2b2a29;
}
.header__nav-ex .nav-ex__main_sub {
  position: absolute;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  z-index: 1;
  display: none;
}
.header__nav-ex .nav-ex__main_sub a {
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid #e1e1e1;
}
.header__nav-ex .nav-ex__main_sub a:last-child {
  border-bottom: none;
}
.header__nav-ex .nav-ex__main-mob {
  position: absolute;
  top: 50px;
  left: 0;
  background-color: #2b2a29;
  border: 1px solid #e1e1e1;
  border-top: none;
  z-index: 3;
}
.header__nav-ex .nav-ex__main-mob_menu {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-left: 0;
  padding-right: 0;
  margin-right: auto;
}
.header__nav-ex .nav-ex__main-mob_item {
  width: 100%;
}
.header__nav-ex .nav-ex__main-mob_item a {
  display: block;
  color: #fff;
  background-color: transparent;
  text-decoration: none;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 40px;
  padding-right: 40px;
  transition: all ease-in-out 0.2s;
}
.header__nav-ex .nav-ex__main-mob_item a:visited {
  color: #fff;
  background-color: transparent;
}
.header__nav-ex .nav-ex__main-mob_item a:active {
  color: #fff;
  background-color: #a7a7a7;
}
.header__nav-ex .nav-ex__main-mob_item a:hover {
  color: #fff;
  background-color: #a7a7a7;
}
.main-content > .container {
  padding-left: 0;
  padding-right: 0;
}
.left {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.center {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.right {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.content-simple {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 23px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
}
.content-simple h1,
.content-simple .h1 {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 7px;
}
.partners {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 23px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.partners h1,
.partners .h1 {
  font-family: 'robotoslab';
  font-weight: 700;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 7px;
  margin-left: 16px;
  margin-right: 16px;
}
.partners__wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.partners__item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
  color: #000;
  text-decoration: none;
  transition: background ease-out 0.1s;
}
.partners__item:hover {
  background-color: #e4f2ec;
}
.partners__item_logo {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 36px;
  border: 1px solid #e1e1e1;
  margin-right: 14px;
}
.partners__item_logo img {
  width: 80%;
}
.partners__item_caption {
  font-size: 13px;
}
.partners__item_caption .title {
  font-weight: 700;
}
.news-list,
.article-list {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 23px;
  padding-bottom: 14px;
  margin-bottom: 20px;
}
.news-list h1,
.article-list h1,
.news-list .h1,
.article-list .h1 {
  font-family: 'robotoslab';
  font-weight: 700;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 7px;
  margin-left: 16px;
  margin-right: 16px;
}
.news-list__wrapper,
.article-list__wrapper {
  padding-left: 16px;
  padding-right: 16px;
}
.news-list__item,
.article-list__item {
  position: relative;
  margin-bottom: 25px;
}
.news-list__item .time,
.article-list__item .time {
  font-size: 13px;
  color: #5a5a5a;
  line-height: 1.7;
}
.news-list__item .time .today,
.article-list__item .time .today {
  font-weight: 700;
  color: #a73439;
}
.news-list__item .img,
.article-list__item .img {
  position: relative;
  width: 100%;
  height: 165px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: 20px;
}
.news-list__item .img .flag-wrapper,
.article-list__item .img .flag-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end;
}
.news-list__item .img .flag,
.article-list__item .img .flag {
  font-size: 13px;
  color: #fff;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 9px;
  padding-right: 9px;
  border-bottom-left-radius: 3px;
}
.news-list__item .img .flag.market,
.article-list__item .img .flag.market {
  background-color: rgba(30, 113, 186, 0.8);
}
.news-list__item .img .flag.partner,
.article-list__item .img .flag.partner {
  background-color: rgba(54, 175, 115, 0.8);
}
.news-list__item .img .flag.project,
.article-list__item .img .flag.project {
  background-color: rgba(179, 77, 84, 0.8);
}
.news-list__item h2,
.article-list__item h2 {
  font-family: 'robotoslab';
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0;
}
.news-list__item .content-preview,
.article-list__item .content-preview {
  font-size: 13px;
  margin-bottom: 10px;
}
.news-list__item a,
.article-list__item a {
  font-size: 13px;
  text-decoration: underline;
}
.news-list__item a.full,
.article-list__item a.full {
  font-weight: 700;
  color: #a73439;
}
.news-list__item a.company,
.article-list__item a.company {
  font-style: italic;
  color: #000;
  text-decoration: underline;
}
.news-list__item a.h2,
.article-list__item a.h2 {
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
}
.news-list__filter {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 7px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 12px;
}
.news-list__filter h1,
.news-list__filter .h1 {
  font-family: 'robotoslab';
  font-weight: 700;
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 5px;
  margin-left: 0;
  margin-right: 16px;
}
.news-list__filter_wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.news-list__filter_wrapper a {
  margin-right: 8px;
}
.news-list__filter_wrapper a:last-child {
  margin-left: auto;
  margin-right: 0;
}
.news-list__switch {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 7px;
  margin-left: 16px;
  margin-right: 16px;
}
.news-list__switch .h1,
.news-list__switch h1 {
  border-bottom: none;
}
.news-list__switch a {
  color: #80807f;
  margin-right: 15px;
  transition: color ease-in-out 0.2s;
}
.news-list__switch a:visited {
  color: #80807f;
}
.news-list__switch a:active {
  color: #a73439;
}
.news-list__switch a:hover {
  color: #a73439;
}
.news-list__switch a:last-child {
  margin-right: 0;
}
.news-list__switch a.active {
  color: #000;
  text-decoration: none;
  cursor: not-allowed;
}
.news-list__switch a.active:visited {
  color: #000;
}
.news-list__switch a.active:active {
  color: #000;
}
.news-list__switch a.active:hover {
  color: #000;
}
.news-list-full--news .news-list__switch {
  display: flex;
  justify-content: space-between;
}
.news-list-full--news .news-list__switch .selects-wrap {
  display: flex;
}
.news-list-full--news .news-list__switch .selects-wrap .select2 {
  margin-left: 10px;
}
.news-item--typical .news-title {
  display: flex;
  justify-content: space-between;
}
.news-item--typical .news-title h1,
.news-item--typical .news-title .h1 {
  flex-grow: 5;
}
.news-item--typical .news-title h1 .tag-wrap,
.news-item--typical .news-title .h1 .tag-wrap {
  display: inline-block;
}
.news-item--typical .news-title h1 .tag-wrap span,
.news-item--typical .news-title .h1 .tag-wrap span {
  font-size: 13px;
  color: #5a5a5a;
  font-weight: 300;
  position: relative;
  padding-left: 20px;
}
.news-item--typical .news-title h1 .tag-wrap span::before,
.news-item--typical .news-title .h1 .tag-wrap span::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  left: 7px;
  top: 5px;
}
.news-item--typical .news-title h1 .tag-wrap .market::before,
.news-item--typical .news-title .h1 .tag-wrap .market::before {
  background: #1e71ba;
}
.news-item--typical .news-title h1 .tag-wrap .partner::before,
.news-item--typical .news-title .h1 .tag-wrap .partner::before {
  background: #36af73;
}
.news-item--typical .news-title h1 .tag-wrap .project::before,
.news-item--typical .news-title .h1 .tag-wrap .project::before {
  background: #b34d54;
}
.news-item--typical .news-title .news-date {
  flex-grow: 1;
  text-align: right;
}
.not-found {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 23px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 14px;
  margin-bottom: 20px;
}
.order-list {
  font-size: 13px;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 23px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
}
.order-list h1,
.order-list .h1 {
  font-family: 'robotoslab';
  font-weight: 700;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 7px;
}
.order-list__counter_value {
  font-size: 16px;
  font-weight: 700;
}
.order-list__item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 94%;
  color: #000;
  text-decoration: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 20px;
  transition: all ease-in-out 0.2s;
}
.order-list__item:hover {
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
}
.order-list__item_top {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 10px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
}
.order-list__item_main {
  padding-top: 24px;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  flex-grow: 1;
}
.order-list__item_main h2 {
  font-family: 'robotoslab';
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0;
  padding-left: 12px;
  padding-right: 12px;
}
.order-list__item_main .content-preview {
  padding-bottom: 15px;
  padding-left: 12px;
  padding-right: 12px;
}
.order-list__item_status {
  color: #fff;
  line-height: 13px;
  border-radius: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 12px;
  padding-right: 12px;
}
.order-list__item_time {
  color: #606060;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 10px;
}
.order-list__item_geo {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e1e1e1;
  padding-top: 10px;
  padding-bottom: 15px;
  padding-left: 12px;
  padding-right: 12px;
}
.order-list__item_geo svg {
  width: 18px;
  height: 18px;
  margin-right: 12px;
}
.order-list__item.opened .order-list__item_top {
  background-color: #d2efe2;
}
.order-list__item.opened .order-list__item_status {
  background-color: #22b573;
}
.order-list__item.closed {
  cursor: not-allowed;
}
.order-list__item.closed .order-list__item_top {
  background-color: #dad5d5;
}
.order-list__item.closed .order-list__item_status {
  background-color: #7f7f7f;
}
.order-list__item.closed:hover {
  box-shadow: none;
}
.gallery {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 23px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
}
.gallery__item {
  position: relative;
  margin-bottom: 20px;
  outline: none;
}
.gallery__item_inner {
  position: relative;
  width: 100%;
  min-height: 277px;
}
.gallery__item_inner .img {
  width: 100%;
  height: 100%;
  min-height: 277px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.gallery__item_inner .hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(179, 77, 84, 0.3);
  z-index: 1;
  opacity: 0;
  transition: opacity ease-in-out 0.2s;
}
.gallery__item_inner .hover svg {
  position: absolute;
  top: 13px;
  right: 23px;
  width: 28px;
  height: 28px;
  fill: #fff;
}
.gallery__item:hover .hover {
  opacity: 1;
}
.mfp-close__gallery {
  top: 50px !important;
}
.mfp-close__gallery svg {
  fill: #fff !important;
}
img.mfp-img {
  max-height: 90vh !important;
}
.mfp-title {
  padding-right: 0;
}
.mfp-counter {
  top: -25px;
  right: 10px;
  color: #fff;
  font-size: 16px;
}
.mfp-pager {
  position: relative;
}
.mfp-pager .dots {
  position: relative;
  justify-content: space-between;
  height: 82px;
}
.mfp-pager .dots li {
  border: 1px solid transparent;
}
.mfp-pager .dots li.active {
  border: 1px solid #e1e1e1;
}
.mfp-pager .dots button {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  border: none;
  background-color: transparent;
}
.mfp-pager .dots img {
  height: 80px;
}
.item-list {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 23px;
  padding-bottom: 50px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
}
.item-list__top {
  position: relative;
  margin-bottom: 20px;
}
.item-list__top_stats {
  color: #7f7f7f;
}
.item-list__top_sort {
  top: 0;
  right: 0;
}
.item-list__main {
  display: table;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
}
.item-list__main_head {
  display: table-header-group;
}
.item-list__main_results {
  display: table-row-group;
}
.item-list__main_cell {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #e1e1e1;
  padding-top: 13px;
  padding-bottom: 12px;
  padding-left: 5px;
  padding-right: 5px;
}
.item-list__main_cell.head {
  font-weight: 700;
  background-color: #fafafa;
  padding-top: 4px;
  padding-bottom: 4px;
}
.item-list__main_cell.phone {
  min-width: 143px;
  font-weight: 700;
}
.item-list__main_cell.price {
  position: relative;
  min-width: 96px;
}
.item-list__main_cell.price svg {
  position: relative;
  top: -5px;
  width: 15px;
  height: 15px;
  fill: #4e4d4f;
}
.item-list__main_cell input:focus + .item-list__price_tooltip.units {
  opacity: 1;
  z-index: 1;
}
.item-list__main_row {
  display: table-row;
}
.item-list__main_row.blank_row {
  display: none;
}
.item-list__main_row a.expand {
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  width: 30px;
  height: 30px;
  display: none;
  transition: all ease-in-out 0.2s;
}
.item-list__main_row a.expand svg {
  fill: #e1e1e1;
}
.item-list__main_row a.expand.active svg {
  transform: rotate(180deg);
}
.item-list__main_row.mode-color .item-list__main_cell {
  background-color: #fdfae9;
}
.item-list__main_row.mode-bold .item-list__main_cell {
  font-weight: 700;
}
.item-list__phone.holder {
  font-weight: 400;
  cursor: pointer;
  color: #a73439;
  text-decoration: underline;
}
.item-list__price {
  text-decoration: underline;
  cursor: pointer;
  z-index: 1;
}
.item-list__price_tooltip {
  position: absolute;
  top: 2px;
  left: -100px;
  font-size: 12px;
  white-space: nowrap;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  padding-top: 3px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  border-bottom-right-radius: 0;
  opacity: 0;
  z-index: -1;
  transition: all ease-in-out 0.2s;
}
.item-list__price_tooltip.units {
  top: -15px;
  left: -130px;
}
.item-list__price:hover + .item-list__price_tooltip {
  opacity: 1;
  z-index: 1;
}
.item-card {
  position: relative;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 16px;
  padding-bottom: 13px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
}
.item-card .img {
  min-height: 226px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: 16px;
}
.item-card .stats {
  margin-bottom: 20px;
}
.item-card .stats h2 {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 7px;
  margin-bottom: 9px;
}
.item-card .stats .item-card__row {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 7px;
  margin-bottom: 9px;
}
.item-card .stats .item-card__row:last-child {
  margin-bottom: 0;
}
.item-card .add {
  text-align: center;
  width: 100%;
}
.size-list {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 23px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
}
.size-list__group h3 {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 7px;
  margin-bottom: 0;
}
.size-list__group_wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
  padding-bottom: 20px;
  margin-left: -5px;
  margin-right: -5px;
}
.size-list__group_item {
  color: #000;
  text-decoration: none;
  background-color: #fafafa;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  transition: background ease-out 0.2s;
}
.size-list__group_item:hover {
  color: #000;
  background-color: #e4f2ec;
}
.filter-simple {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 23px;
  padding-bottom: 22px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
}
.filter-simple h1,
.filter-simple .h1 {
  margin-bottom: 0;
}
.filter-simple__group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.filter-simple__group a {
  display: block;
  min-width: 85px;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: #7f7f7f;
  background-color: #f0f0f0;
  border-radius: 3px;
  text-decoration: none;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 14px;
  padding-right: 14px;
  margin-left: 10px;
  transition: background ease-in-out 0.2s;
}
.filter-simple__group a:first-child {
  margin-left: 0;
}
.filter-simple__group a:hover {
  background-color: #e8d9d5;
}
.filter-simple__group a.active {
  color: #fff;
  background-color: #b73439;
  cursor: not-allowed;
}
.board-list {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 23px;
  padding-bottom: 22px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
}
.board-list__switch {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin-bottom: 20px;
}
.board-list__switch a {
  color: #000;
  background-color: #fafafa;
  text-decoration: none;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 7px;
  padding-right: 7px;
}
.board-list__switch a:first-child {
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.board-list__switch a:last-child {
  border-right: 1px solid #e1e1e1;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.board-list__switch a.active {
  background-color: #e5f3ed;
}
.board-list__main {
  display: table;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
}
.board-list__main_head {
  display: table-header-group;
}
.board-list__main_results {
  display: table-row-group;
}
.board-list__main_cell {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #e1e1e1;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
.board-list__main_cell.head {
  font-weight: 700;
  background-color: #fafafa;
  padding-top: 4px;
  padding-bottom: 4px;
}
.board-list__main_cell.filter {
  position: relative;
}
.board-list__main_cell.stats {
  text-align: left;
  background-color: transparent;
}
.board-list__main_cell.stats .block-with-text {
  overflow: hidden;
  font-size: 14px;
  letter-spacing: 0;
  width: 80px;
  position: relative;
  line-height: 1.2em;
  max-height: 2.3em;
  margin-right: -1em;
  padding-right: 1em;
  cursor: pointer;
  transition: color ease-in-out 0.1s;
}
.board-list__main_cell.stats .block-with-text:before {
  content: '...';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.board-list__main_cell.stats .block-with-text:after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: white;
}
.board-list__main_cell.stats .block-with-text:hover {
  color: #a73439;
}
.board-list__main_cell.stats .block-with-text.active {
  overflow: visible;
  max-height: none;
}
.board-list__main_cell.stats .block-with-text.active:before {
  content: none;
}
.board-list__main_cell.stats .block-with-text.active:after {
  background-color: transparent;
}
.board-list__main_cell.price {
  position: relative;
  min-width: 96px;
}
.board-list__main_cell.price svg {
  position: relative;
  top: -5px;
  width: 15px;
  height: 15px;
  fill: #4e4d4f;
}
.board-list__main_cell.provider {
  text-align: left;
}
.board-list__main_cell.order {
  white-space: nowrap;
}
.board-list__main_row {
  position: relative;
  display: table-row;
}
.board-list__main_row a.expand {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: block;
  width: 30px;
  height: 30px;
  display: none;
  transition: all ease-in-out 0.2s;
}
.board-list__main_row a.expand svg {
  fill: #e1e1e1;
}
.board-list__main_row a.expand.active svg {
  transform: rotate(180deg);
}
.board-list__main_row.mode-color .board-list__main_cell {
  background-color: #fdfae9;
  position: relative;
  background-clip: padding-box;
}
.board-list__main_row.mode-bold .board-list__main_cell {
  font-weight: 700;
}
.board-list__reset {
  position: relative;
  font-size: 13px;
  color: #5a5a5a;
  text-decoration: none;
  padding-left: 15px;
  white-space: nowrap;
}
.board-list__reset svg {
  position: absolute;
  top: 3px;
  left: 0;
  width: 9px;
  height: 9px;
  fill: #5a5a5a;
}
.board-list__provider_name {
  font-weight: 700;
  color: #000;
}
.board-list__phone_wrapper {
  white-space: nowrap;
}
.board-list__phone.holder {
  font-weight: 400;
  cursor: pointer;
  color: #a73439;
  text-decoration: underline;
}
.board-list-group .board-list__main_cell.stats a {
  color: #000;
}
.board-list-group .boad-list__price.min {
  font-weight: 700;
}
.board-list-group .board-list__main_cell.provider {
  text-align: center;
}
.board-list-group .board-list__main_cell.provider a {
  color: #000;
}
.board-list-group .board-list__main_cell.add a {
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
  background-color: #a73439;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 3px;
}
.promo {
  position: relative;
  height: 365px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -10% 40%;
  margin-bottom: 20px;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}
.promo__scale {
  height: 365px;
}
.promo__scale:before {
  content: '';
  position: absolute;
  top: 0;
  left: -34%;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform: rotate(-70deg);
  z-index: 1;
}
.promo__wrapper {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 310px;
  height: 100%;
  background-color: #fff;
  padding-top: 45px;
  padding-bottom: 65px;
  padding-left: 25px;
  padding-right: 18px;
  z-index: 2;
}
.promo__logo {
  width: 265px;
  margin-bottom: auto;
}
.promo__logo img {
  max-width: 100%;
}
.promo__adress {
  font-size: 16px;
  margin-bottom: 25px;
  align-self: stretch;
}
.promo__phone {
  font-size: 25px;
  font-weight: 700;
  color: #000;
  text-decoration: none;
  margin-bottom: 15px;
  letter-spacing: 0;
}
.promo__phone:hover {
  color: #000;
  text-decoration: none;
}
.promo-inner {
  position: relative;
  height: 135px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -10% 40%;
  margin-bottom: 20px;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}
.promo-inner__scale {
  height: 135px;
}
.promo-inner__scale:before {
  content: '';
  position: absolute;
  top: 0;
  left: 16%;
  width: 300px;
  height: 100%;
  background-color: #fff;
  -webkit-transform: rotate(-70deg);
  transform: rotate(-70deg);
  z-index: 1;
}
.promo-inner__scale:after {
  content: '';
  position: absolute;
  top: 0;
  right: 8%;
  width: 300px;
  height: 100%;
  background-color: #fff;
  -webkit-transform: rotate(-70deg);
  transform: rotate(-70deg);
  z-index: 1;
}
.promo-inner__wrapper {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 18px;
  z-index: 2;
}
.promo-inner__logo {
  align-self: flex-start;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 265px;
  height: 100%;
  margin-bottom: auto;
}
.promo-inner__logo img {
  max-width: 100%;
}
.promo-inner__phone {
  font-size: 25px;
  font-weight: 700;
  color: #000;
  text-decoration: none;
  margin-bottom: 15px;
  letter-spacing: 0;
}
.promo-inner__phone:hover {
  color: #000;
  text-decoration: none;
}
.provider-list__modal-link {
  margin-bottom: 20px;
}
.provider-card {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
}
.provider-card__top {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.provider-card__logo {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 172px;
  height: 105px;
  border: 1px solid #e1e1e1;
  margin-right: 25px;
}
.provider-card__logo img {
  width: 70%;
}
.provider-card__company {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.provider-card__city {
  font-size: 16px;
}
.provider-card__contacts {
  font-size: 16px;
  font-weight: 700;
  margin-top: auto;
}
.provider-card__phone.holder {
  font-weight: 400;
  cursor: pointer;
  color: #a73439;
  text-decoration: underline;
}
.provider-card__more {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: auto;
}
.provider-card__counter {
  font-size: 16px;
  color: #a3a3a3;
}
.provider-card__price {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}
.provider-card__main {
  display: table;
  border-collapse: collapse;
  width: 100%;
}
.provider-card__main_row {
  display: table-row;
}
.provider-card__main_cell {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #e1e1e1;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
.provider-card__main_cell.filter {
  position: relative;
}
.provider-card__main_cell.stats {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
.provider-card__main_cell.price {
  position: relative;
  min-width: 96px;
}
.provider-card__main_cell.price svg {
  position: relative;
  top: -5px;
  width: 15px;
  height: 15px;
  fill: #4e4d4f;
}
.provider-card__main_cell.order {
  white-space: nowrap;
}
.stats {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
}
.stats__item {
  margin-bottom: 15px;
}
.stats__item.verify {
  position: relative;
  z-index: 1;
}
.stats__item.verify .stats__tooltip {
  position: absolute;
  top: 2px;
  left: -100px;
  font-size: 12px;
  white-space: nowrap;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  padding-top: 3px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  border-bottom-right-radius: 0;
  opacity: 0;
  z-index: -1;
  transition: all ease-in-out 0.2s;
}
.stats__item.verify .stats__profile:hover + .stats__tooltip,
.stats__item.verify .stats__contacts:hover + .stats__tooltip {
  opacity: 1;
  z-index: 1;
}
.stats__item.verify svg {
  width: 16px;
  height: 16px;
  fill: #7e7d7f;
}
.stats__profile,
.stats__contacts {
  cursor: pointer;
  border-bottom: 1px solid #d0d0d0;
}
.stats__tooltip {
  font-weight: 400;
}
.stats__value {
  font-weight: 700;
  font-size: 16px;
}
.stats__social {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.stats__social_item {
  margin-right: 10px;
}
.stats__social svg {
  height: 16px;
  fill: #2b2b2b;
  transition: all ease-in-out 0.2s;
}
.stats__social_item:hover svg {
  fill: #b73439;
}
.catalog {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  margin-bottom: 20px;
}
.catalog__col {
  padding-left: 0;
  padding-right: 0;
  border-right: 1px solid #e1e1e1;
}
.catalog__col:last-child {
  border-right: none;
}
.catalog__item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom: 1px solid #e1e1e1;
  padding-top: 45px;
  padding-bottom: 40px;
}
.catalog__item:last-child {
  border-bottom: none;
}
.catalog__item h2 {
  padding-left: 40px;
  padding-right: 40px;
}
.catalog__item a {
  font-size: 16px;
  color: #000;
  text-decoration: none;
  padding-left: 50px;
  padding-right: 40px;
  transition: background ease-out 0.1s;
}
.catalog__item a:before {
  content: '-';
  position: relative;
  left: -5px;
}
.catalog__item a:hover {
  background-color: #e4f2ec;
}
.map {
  margin-bottom: 20px;
}
.footer__main {
  color: #fff;
  padding-top: 29px;
  padding-bottom: 18px;
  background-color: #2b2a29;
  background-image: url('../img/multiples/pointless.svg');
  background-repeat: no-repeat;
  background-size: 55%;
  background-position: 45% 5%;
}
.footer__main a {
  color: #fff;
  text-decoration: none;
}
.footer__sub {
  color: #a7a7a7;
  background-color: #000;
}
.footer__sub .webmotor {
  text-align: right;
}
.footer__sub .webmotor a,
.footer__sub .webmotor span {
  color: #a7a7a7;
}
.footer__copyright {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.footer__copyright_logo {
  position: relative;
  max-width: 185px;
  margin-bottom: 25px;
}
.footer__copyright_logo svg {
  width: 100%;
  height: 100%;
  max-height: 91px;
}
.footer__copyright_caption {
  align-self: stretch;
}
.footer__copyright_caption p {
  margin-bottom: 0;
  line-height: 2;
}
.footer__menu {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
}
.footer__menu_group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.footer__menu_group a:visited:not(.btn),
.footer__menu_group .a:visited:not(.btn),
.footer__menu_group .content a:visited:not(.btn),
.footer__menu_group content .a:visited:not(.btn) {
  color: #fff;
}
.footer__menu_group-heading {
  font-family: 'robotoslab';
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 33px;
}
.footer__menu_group-item {
  line-height: 2;
}
.footer__contacts {
  text-align: right;
}
.footer__contacts_social {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end;
  padding-top: 4px;
  margin-bottom: 42px;
}
.footer__contacts_social a {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer__contacts_social svg {
  height: 15px;
  fill: #fff;
}
.footer__contacts_social-item {
  margin-left: 16px;
}
.footer__contacts_social-item:first-child {
  margin-left: 0;
}
.footer__contacts_phone-item:first-child {
  margin-bottom: 15px;
}
.footer__contacts_phone a {
  font-family: 'robotoslab';
  font-size: 18px;
}
.footer-ex__main {
  color: #fff;
  padding-top: 29px;
  padding-bottom: 18px;
  background-color: #2b2a29;
  background-image: url('../img/multiples/pointless.svg');
  background-repeat: no-repeat;
  background-size: 55%;
  background-position: 45% 5%;
}
.footer-ex__main a {
  min-width: 86px;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  margin-right: 70px;
}
.footer-ex__main a:hover {
  color: #a73439;
}
.footer-ex__sub {
  color: #a7a7a7;
  background-color: #000;
}
.footer-ex__sub .warning {
  text-align: right;
}
.footer-ex__sub .policy a {
  color: #a7a7a7;
}
.footer-ex__sub .webmotor {
  text-align: right;
}
.footer-ex__sub .webmotor a,
.footer-ex__sub .webmotor span {
  color: #a7a7a7;
}
.footer-ex__copyright {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.footer-ex__copyright_logo {
  position: relative;
  max-width: 185px;
  margin-bottom: 25px;
}
.footer-ex__copyright_logo svg {
  width: 100%;
  height: 100%;
  max-height: 23px;
}
.footer-ex__menu {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
/**
*/
.order-item {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding: 0;
  margin-bottom: 20px;
}
.order-item__left-col {
  padding: 23px 16px;
}
.order-item__left-col a.h2 {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
}
.order-item__right-col {
  border-left: 1px solid #e1e1e1;
  background-color: #e8f7f0;
  flex-direction: column;
  justify-content: space-between;
}
.order-item__info-wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 50px 16px 23px;
}
.order-item__info-wrap__info-item {
  max-width: 50%;
  flex-basis: 50%;
  margin-bottom: 10px;
}
.order-item__info-wrap__info-item b {
  display: inline-block;
  font-size: 16px;
}
.location-info-wrap {
  border-top: 1px solid #e1e1e1;
  padding: 18px 14px;
}
.location-info-wrap .location-info {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: flex-start;
}
.location-info-wrap .location-info .icon {
  position: relative;
  top: -5px;
  width: 20px;
  height: 30px;
  fill: #555;
  margin-right: 7px;
}
.order-item__closed .h2 {
  color: #7f7f7f;
}
.order-item__closed .order-item__right-col {
  background: #fafafa;
}
.order-item__closed .order-item__info-wrap__info-item b {
  color: #7f7f7f;
}
.order-item__closed .btn--primary {
  background: #7f7f7f;
  border-color: #7f7f7f;
}
.order-item__closed .btn--primary.disabled {
  cursor: not-allowed;
}
.pagination-wrapper,
.content .pagination-wrapper {
  width: 100%;
  text-align: right !important;
}
/*fixed merge*/
.sidebar-checkbox-wrap {
  display: flex;
  width: 100%;
  margin-bottom: 7px;
  padding-left: 40px;
  transition: background ease-in 0.1s;
}
.sidebar-checkbox-wrap:hover {
  background-color: #e4f2ec;
}
.sidebar-checkbox-wrap .site-form__label-checkbox {
  width: 16px;
  height: 16px;
  min-width: 16px;
}
.sidebar-checkbox-wrap .site-form__icon-checked {
  fill: #19c31e;
}
.sidebar-checkbox-wrap .site-form-checkbox-text {
  font-size: 14px;
  color: #000;
  font-weight: 400;
  cursor: pointer;
}
.order-execution-wrap {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 0px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
}
input[type="text"].table-input {
  width: 100%;
  max-width: 100%;
  background-color: #fafae6;
  border-color: #e6e6e6;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  height: 28px;
  padding-left: 5px;
  padding-right: 5px;
}
input[type="text"].table-input:focus {
  background-color: #fff;
}
.order-item__execution {
  padding-top: 20px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
}
.order-item__welcome {
  padding-top: 20px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
}
.add-row-wrap {
  top: -1px;
  bottom: 0;
  right: -55px;
  width: 55px;
  border: 0px solid #e1e1e1;
  border-width: 1px 1px 1px 0;
  background-color: #fff;
  padding: 20px 0 0 10px ;
  box-shadow: 2px 2px 2px #cdcdcd;
}
.add-row-wrap .changeRow {
  text-decoration: none;
  margin-right: 2px;
  display: inline;
  cursor: pointer;
}
.add-row-wrap .changeRow span {
  display: none;
}
.add-row-wrap .fa {
  color: #333;
  font-size: 20px;
}
.add-row-wrap .icon {
  width: 14px;
  height: 14px;
  fill: #333;
}
.add-row-wrap .icon:hover {
  fill: #555;
}
/* .add-row-wrap {
  display: none;
}
.item-list__main_row:hover {
  .add-row-wrap {
    display: block;
  }
} */
.hidden-block {
  display: none;
}
input[type="text"].price-input {
  max-width: 100px;
  width: 100px;
}
.order-card__order-info {
  position: relative;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 16px;
  padding-bottom: 13px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
}
dl.top-info {
  background: #e8f7f0;
  padding: 10px;
}
dl.top-info dd {
  padding-left: 0;
  margin-left: 0;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
}
dl.info {
  padding: 10px;
}
dl.info dd {
  padding-left: 0;
  margin-left: 0;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
}
.collapse-text .holder {
  text-decoration: underline;
  cursor: pointer;
}
.files-wrap {
  padding-top: 8px;
}
.files-wrap .doc {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 14px;
}
.files-wrap .icon {
  width: 24px;
  height: 33px;
  fill: #000;
  margin-right: 8px;
}
h1 span.status,
.h1 span.status {
  display: inline-block;
  color: #fff;
  background-color: #22b573;
  border-radius: 15px;
  font-size: 13px;
  padding: 2px 6px 3px;
  position: relative;
  top: -3px;
  text-transform: lowercase;
  margin-left: 15px;
}
/*ymaps*/
.map-wrap {
  padding-top: 75px;
  padding-bottom: 45px;
}
.map {
  height: 350px;
  background-position: 50% 50%;
  background-repeat: repeat;
}
.popover-wrapper {
  background: #fff;
  border: 1px solid #f6f6f6;
  padding: 10px;
  position: relative;
}
.popover-wrapper p {
  font-size: 14px;
  margin-bottom: 5px;
}
.popover-wrapper .popover-close {
  text-align: right;
  width: 20px;
  display: block;
  padding: 3px;
  position: absolute;
  right: 5px;
  top: 5px;
}
.popover-wrapper a {
  color: #1b7eb0;
}
.popover-wrapper a:hover {
  text-decoration: none;
}
.popover-close .icon {
  fill: #000;
  width: 14px;
  height: 14px;
}
.contacts-info {
  margin-bottom: 20px;
}
.contacts-info p {
  margin-bottom: 10px;
}
.contacts-info p b {
  color: #000;
  font-weight: 500;
}
.popover-title.h5 {
  margin-bottom: 10px;
}
.presentation-block label.error,
.footer-block label.error {
  color: #4e8d92;
  font-weight: 300;
  padding: 4px 22px;
  border-radius: 22px;
  margin-top: 5px;
  display: block;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 100%;
}
.white-popup {
  position: relative;
  background: #FFF;
  padding: 20px;
  width: auto;
  max-width: 500px;
  margin: 20px auto;
}
.mobile-banner-block .banner__side-long {
  display: none;
}
/*таблица*/
.item-list__main_cell .head {
  width: 100%;
  display: none;
}
.order-item-mobile .order-list__item {
  background-color: #fff;
}
.order-item-mobile .order-list__item:hover {
  box-shadow: 0 0 0px 0px rgba(0, 0, 0, 0.1);
}
.order-item-mobile .order-list__item .order-list__item_top {
  background-color: #d2efe2;
}
.order-item-mobile .btn {
  margin-left: 10px;
  margin-bottom: 10px;
}
.order-item-mobile a.h2 {
  padding-left: 12px;
  padding-right: 12px;
  text-decoration: none;
}
.order-item-mobile a.btn--small {
  font-family: 'proxima_nova';
  font-size: 14px;
  font-weight: 300;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: auto;
  margin-bottom: 0;
  border: none;
  border-radius: 3px;
}
/*список заявок на мобильном*/
.order-item__mobile-footer {
  display: none;
}
@media (max-width: 767px) {
  .item-list__main_results {
    display: block;
  }
  .item-list__main_cell {
    min-width: 100%;
  }
  .item-list__main_cell .head {
    display: inline-block;
    font-weight: 600;
    min-width: 50%;
  }
  .order-item__right-col {
    order: 1;
    border-left: 0;
    border-radius: 0 0 10px 10px;
  }
  .order-item__right-col .location-info-wrap {
    display: none;
  }
  .order-item__right-col .order-item__info-wrap {
    padding: 10px;
  }
  .order-item__left-col {
    order: 2;
  }
  .order-item__mobile-footer {
    display: block;
    order: 3;
    background-color: #e8f7f0;
    border-radius: 10px 10px 0 0;
  }
  .order-item__mobile-footer .location-info-wrap {
    border: 0;
  }
  .order-item__closed .order-item__mobile-footer {
    background-color: #fafafa;
  }
  .order-item__closed .btn {
    cursor: not-allowed;
  }
  .right__item .btn.btn--primary.add {
    display: none;
  }
  .item-list__caption .btn.btn--primary.add {
    display: inline-block;
  }
  .add-row-wrap {
    top: auto;
    bottom: auto;
    right: auto;
    width: 100%;
    border: 0px solid #e1e1e1;
    border-width: 1px 1px 1px 1px;
    background-color: #fff;
    padding: 20px 10px 10px;
    box-shadow: 0px 0px 0px #cdcdcd;
  }
  .add-row-wrap .icon {
    margin-right: 10px;
  }
  .add-row-wrap .changeRow {
    text-decoration: none;
    margin-right: 2px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }
  .add-row-wrap .changeRow span {
    display: block;
  }
}
.order-item-mobile .order-list__item.closed .order-list__item_top {
  background: #dad5d5;
}
.order-item-wrap .order-item-mobile {
  display: none;
}
.order-item-wrap .order-item {
  display: block;
}
.board-list__main_row ins {
  position: relative;
}
.mfp-bg {
  height: 100% !important;
}
.mfp-wrap {
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 768px) {
  .order-item-wrap .order-item-mobile {
    display: block;
  }
  .order-item-wrap .order-item {
    display: none;
  }
}
.bg-wrapper {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding-top: 23px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
}
.cabinet-logo {
  max-width: 100%;
}
.cabinet-menu-list {
  display: flex;
}
.cabinet-menu-list-item {
  padding-right: 20px;
  display: inline-block;
}
.cabinet-menu-list-item__link {
  font-family: 'robotoslab';
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 33px;
}
.cabinet-head {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 7px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}
.cabinet-head h1,
.cabinet-head .h1 {
  border-bottom: none;
  margin-bottom: 0;
  margin-right: auto;
}
.cabinet-head button {
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 5px;
}
.cabinet-head button:first-child {
  margin-left: 0;
}
.cabinet-head form {
  margin-left: 5px;
}
@media (max-width: 767px) {
  .cabinet-head {
    flex-direction: column;
  }
  .cabinet-head form {
    width: 100%;
    margin-left: 0;
  }
  .cabinet-head form button {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .cabinet-head button {
    width: 100%;
    margin-left: 0;
    margin-bottom: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.cabinet-head-links a {
  margin-left: 15px;
}
.cabinet-company {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 5px;
}
.cabinet-item__name {
  font-size: 14px;
}
.cabinet-item-block {
  /* Firefox 19+ */
  /* Firefox 18- */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* FORM STYLE */
  /* Firefox 19+ */
  /* Firefox 18- */
  margin-bottom: 30px;
}
.cabinet-item-block .h1,
.cabinet-item-block h1 {
  border-bottom: none;
}
.cabinet-item-block .form-row input {
  display: block;
  width: 100%;
  position: relative;
  z-index: 5;
  height: 30px;
  line-height: 30px;
  font-weight: 300;
  color: #4f4f4f;
  font-size: 16px;
  padding: 0 8px;
  border: 1px solid #adadad;
  background-color: #f6f6f6;
}
.cabinet-item-block .form-row input:focus {
  outline: 0;
}
.cabinet-item-block .form-row input:focus ~ .label__caption,
.cabinet-item-block .form-row.filled .label__caption {
  opacity: 1;
  font-size: 16px;
  top: -30%;
  background: #fbfbfc;
  z-index: 22;
  height: 20px;
  line-height: 1;
}
.cabinet-item-block .form-row input::-webkit-input-placeholder {
  opacity: 0;
}
.cabinet-item-block .form-row input::-moz-placeholder {
  opacity: 0;
}
.cabinet-item-block .form-row input:-moz-placeholder {
  opacity: 0;
}
.cabinet-item-block .form-row input:-ms-input-placeholder {
  opacity: 0;
}
.cabinet-item-block .form-row input:focus::-webkit-input-placeholder {
  opacity: 1;
}
.cabinet-item-block .form-row input:focus::-moz-placeholder {
  opacity: 1;
}
.cabinet-item-block .form-row input:focus:-moz-placeholder {
  opacity: 1;
}
.cabinet-item-block .form-row input:focus:-ms-input-placeholder {
  opacity: 1;
}
.cabinet-item-block .form-row label.error {
  display: inline-block;
  position: static;
  color: #D10B0D;
}
.cabinet-item-block .form-row input.error {
  border-bottom: 2px solid #D10B0D;
}
.cabinet-item-block ::-webkit-input-placeholder {
  color: #615f5f;
  opacity: 1;
}
.cabinet-item-block ::-moz-placeholder {
  color: #615f5f;
  opacity: 1;
}
.cabinet-item-block :-moz-placeholder {
  color: #615f5f;
  opacity: 1;
}
.cabinet-item-block :-ms-input-placeholder {
  color: #615f5f;
  opacity: 1;
}
.cabinet-item-block .callback-form-button-wrapper {
  text-align: center;
}
.cabinet-item-block .site-form-row--checkbox {
  margin-bottom: 5px;
}
.cabinet-item-block .site-form__icon-checked {
  fill: #a73439;
}
.cabinet-item-block .site-form__input-checkbox:focus ~ .site-form__label-checkbox {
  border-color: #a73439;
}
.cabinet-item-block .site-form-checkbox-text {
  font-size: 13px;
}
.cabinet-item-block .h3 {
  font-size: 15px;
}
.cabinet-item-block .table {
  min-width: 100%;
}
.cabinet-item-block .table tr {
  border-bottom: 1px solid;
}
.cabinet-item-block .table a {
  font-size: 16px;
}
.cabinet-item-block table.table th {
  padding: 6px 15px 6px 0;
  font-weight: 700;
  color: #000;
  text-align: left;
  vertical-align: middle;
}
.cabinet-item-block table.table td {
  padding: 6px 15px 6px 0;
  border-bottom: 1px solid #9d9e9e;
}
.cabinet-item-block table.table td .h2 {
  margin-bottom: 0;
}
.cabinet-item-block table.table td span {
  font-size: 13px;
  display: block;
  color: #555;
}
.cabinet-controls {
  margin-bottom: 30px;
}
.cabinet-stat__controls {
  margin: 10px 0;
  display: flex;
}
.cabinet-stat__controls button.btn {
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  font-family: 'robotoslab';
  font-size: 14px;
  margin-right: 10px;
}
.cabinet-stat__controls button.btn.active,
.cabinet-stat__controls button.btn:hover {
  border-color: #a73439;
  background-color: #a73439;
  color: #fff;
}
.cabinet-controls {
  text-align: center;
}
.cabinet-controls a.btn {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 9px;
  padding-right: 9px;
  font-size: 15px;
}
.cabinet-controls a.btn.active,
.cabinet-controls a.btn:hover {
  border-color: #a73439;
  background-color: #a73439;
  color: #fff;
}
.cabinet-rate__name {
  display: flex;
  margin-bottom: 30px;
}
.cabinet-rate-form {
  background: url('../img/cabinet/individual-rate-bg.jpg') bottom left / cover no-repeat;
  margin-left: -16px;
  margin-right: -16px;
  padding: 42px 16px;
  margin-bottom: 35px;
  position: relative;
}
.cabinet-rate-form:after {
  content: '';
  display: block;
  background: url('../img/cabinet/individual-rate-after.png') center center / cover no-repeat;
  width: 402px;
  height: 226px;
  position: absolute;
  right: 0;
  bottom: -42px;
}
.cabinet-rate-form .h1,
.cabinet-rate-form h1 {
  color: white;
  border-bottom: none;
  margin-bottom: 2px;
}
.cabinet-rate-form .p {
  color: #fefefe;
  font-family: 'proxima_nova';
  font-size: 14px;
  opacity: 0.8;
  font-weight: 400;
  line-height: 21px;
}
.cabinet-rate__status {
  color: black;
  font-family: 'proxima_nova';
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-decoration: underline;
  margin-left: 14px;
}
.cabinet-rate-item {
  margin-bottom: 30px;
}
.cabinet-rate-item__head {
  background-color: #272625;
  background-image: linear-gradient(to right, #2a2928 0%, black 100%);
  border-radius: 10px 10px 0px 0px;
  color: #fefefe;
  font-family: RobotoSlab;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 15px;
}
.cabinet-rate-item-list {
  border: 1px solid #e6e6e6;
  border-bottom: none;
}
.cabinet-rate-item-list-item {
  opacity: 0.8;
  color: black;
  padding: 13px;
  text-align: center;
  font-family: 'proxima_nova';
  font-size: 14px;
  border-bottom: 1px solid #e6e6e6;
  font-weight: 400;
  line-height: 21px;
}
.cabinet-rate-item-list-item--title {
  font-size: 16px;
}
.cabinet-rate-item-footer {
  border: 1px solid #e6e6e6;
  border-top: none;
  text-align: center;
  padding: 20px 40px 35px;
}
.cabinet-rate-item-footer .btn {
  width: 100%;
}
.scroll-table {
  width: 100%;
  overflow-x: auto;
}
.scroll-table table th {
  min-width: 150px;
}
/**
* файл для стилей для разных устройств
*/
@media (max-width: 1210px) {
  body {
    overflow-x: hidden;
  }
}
@media (max-width: 1199px) {
  body {
    margin-left: -10px;
    margin-right: -10px;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  .order-list .row,
  .site-form .row,
  .news-list__wrapper,
  .article-list__wrapper {
    margin-left: -10px;
    margin-right: -10px;
  }
  .header__login.misc,
  .header__login.user {
    order: 1;
    height: 42px;
    border-bottom: 1px solid #e1e1e1;
  }
  .header__counter {
    order: 3;
  }
  .header__social {
    order: 2;
    height: 42px;
    border-bottom: 1px solid #e1e1e1;
  }
  .header__nav .nav__main {
    padding-left: 0;
    padding-right: 0;
  }
  .nav__main_menu {
    border-bottom: 1px solid #e1e1e1;
  }
  .header__nav .nav__main_search {
    position: relative;
    height: 49px;
    max-width: unset;
  }
  .header__nav .nav__main_order {
    max-width: unset;
  }
  .header__nav .nav__top_wrapper {
    padding-right: 10px;
  }
  .promo-inner__scale .no-padding {
    padding: 0 !important;
  }
  .news-list__filter_wrapper a {
    padding-left: 5px;
    padding-right: 5px;
  }
  .slider__side_item .btn {
    width: 99%;
    margin-left: auto;
    margin-right: auto;
  }
  .promo__scale:before {
    left: -30%;
  }
  .catalog__main {
    padding-left: 0;
    padding-right: 0;
  }
  .footer__main {
    background-size: 85%;
    background-position: 70% 20%;
  }
  .order-item__right-col {
    border-right: 1px solid #e1e1e1;
    border-top: 1px solid #e1e1e1;
  }
}
@media (max-width: 1023px) {
  .left__index {
    order: 1;
  }
  .left__item {
    order: 2;
  }
  .left__provider {
    order: 1;
  }
  .center__index {
    order: 3;
  }
  .center__provider {
    order: 3;
  }
  .right__index {
    order: 2;
  }
  .right__index .banner__side-short {
    order: 1;
  }
  .right__index .slider__side {
    order: 2;
  }
  .right__index .partners {
    order: 4;
  }
  .right__index .banner__side-long {
    order: 3;
  }
  .right__item {
    order: 1;
  }
  .right__item .item-card {
    order: 1;
  }
  .right__item .banner__side-tiny {
    order: 2;
  }
  .right__provider {
    order: 2;
  }
  .right__news-list {
    display: none;
  }
  .header__logo a svg {
    width: 310px;
  }
  .header__contacts {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
  }
  .header__contacts_item {
    width: 100%;
    text-align: center;
    padding-left: 0;
  }
  .header__contacts_item.city a {
    left: -5px;
  }
  .news-list__item .img,
  .article-list__item .img {
    height: 288px;
  }
  .item-card {
    order: 1;
  }
  .item-card .img {
    min-height: 600px;
  }
  .mfp-pager .dots {
    display: none;
  }
  .promo__wrapper {
    max-width: unset;
  }
  .promo__scale:before {
    left: -13%;
  }
  .stats__item.social {
    margin-bottom: 5px;
  }
  .catalog__item h2 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .catalog__item a {
    padding-left: 15px;
    padding-right: 10px;
  }
  .footer__main {
    background-size: 100%;
    background-position: 20% -30%;
  }
  .footer__menu {
    justify-content: flex-end;
  }
  .footer__menu_group {
    margin-left: 30px;
  }
  .footer__menu_group:first-child {
    margin-left: 0;
  }
  .footer__contacts {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .footer__contacts_social {
    margin-bottom: 0;
  }
  .footer__contacts_phone {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
  }
  .footer__contacts_phone-item {
    margin-right: 20px;
  }
  .footer__contacts_phone-item:last-child {
    margin-right: 0;
  }
  .left__orders-list {
    flex-direction: row;
  }
  .left__orders-list .sidebar {
    max-width: 50%;
    flex-basis: 50%;
  }
  .left__orders-list .banner__side-long {
    max-width: 50%;
    flex-basis: 50%;
  }
  .left__orders-list .banner__side-long {
    display: none;
  }
  .mobile-banner-block .banner__side-long {
    display: block;
    height: auto !important;
  }
}
@media (min-width: 769px) {
  .header__mob {
    display: none;
  }
  .header__mob-search {
    display: none;
  }
  .header__mob-search-wrapper {
    display: none !important;
  }
  .header__contacts_item.city a svg.geo {
    display: none;
  }
  .nav__main-mob {
    display: none !important;
  }
}
@media (max-width: 768px) {
  .banner__lg {
    padding-top: 0;
    margin-bottom: 0;
  }
  .header__main_top {
    height: 42px;
    border-bottom: none;
  }
  .header__counter {
    display: none !important;
  }
  .header__main_contacts {
    align-items: center;
    padding-top: 15px;
    padding-bottom: 10px;
    padding-left: 60px;
  }
  .header__contacts {
    margin-left: 0;
    margin-right: 0;
  }
  .header__contacts_item {
    text-align: left;
  }
  .header__contacts_item.phone {
    display: none;
  }
  .header__contacts_item.city a {
    left: 0;
    font-size: 16px;
  }
  .header__contacts_item.city a svg.down {
    display: none;
  }
  .header__contacts_item.city a svg.geo {
    top: 2px;
    right: -24px;
    width: 20px;
  }
  .header__contacts_item.city {
    position: relative;
    top: -5px;
    text-align: right;
    padding-right: 80px;
  }
  .header__contacts_item.city .caption {
    display: none;
  }
  .header__nav .nav__top_wrapper {
    display: none;
  }
  .header__nav .nav__main {
    display: none;
  }
  .header__contacts {
    align-items: flex-start;
  }
  .breadcrumb {
    padding-left: 16px;
  }
  .header__nav-ex .nav-ex__main_item a {
    padding-left: 10px;
    padding-right: 10px;
  }
  .header__mob_inner,
  .header__mob_inner.folded {
    width: 35px;
    height: 25px;
  }
  .news-list__switch {
    flex-direction: column;
  }
  .news-list__switch .selects-wrap {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .news-list-full--news .news-list__switch .selects-wrap .select2 {
    margin-left: 0;
    margin-right: 10px;
  }
  .left__orders-list {
    flex-direction: row;
  }
  .left__orders-list .sidebar {
    max-width: 50%;
    flex-basis: 50%;
  }
  .left__orders-list .banner__side-long {
    max-width: 50%;
    flex-basis: 50%;
  }
  .provider-card {
    position: relative;
    padding-bottom: 47px;
  }
  .provider-card__top {
    justify-content: flex-start;
    margin-bottom: 30px;
  }
  .provider-card__main {
    display: none;
  }
  .provider-card__counter {
    display: none;
  }
  .provider-card__more {
    position: absolute;
    bottom: 17px;
    left: 213px;
  }
}
@media (max-width: 767px) {
  body {
    margin-left: 0;
    margin-right: 0;
  }
  .left__index {
    order: 1 !important;
  }
  .center__index {
    order: 2 !important;
  }
  .right__index {
    order: 3 !important;
  }
  .header__login {
    z-index: 1;
  }
  .header__social {
    position: relative;
    top: -42px;
    display: none;
  }
  .header__counter {
    margin-top: -42px;
  }
  .header__main_contacts {
    padding-top: 15px;
    padding-left: 0;
  }
  .header__logo a svg {
    width: 170px;
    height: 21px;
  }
  .nav__main_menu {
    flex-direction: column;
  }
  .header__nav .nav__main_item {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #e1e1e1;
  }
  .header__nav .nav__main_item:last-child {
    border-bottom: none;
  }
  .header__nav .nav__main_sub a {
    background-color: #f0f0f0;
  }
  .header__nav .nav__main_item.multi span {
    left: 0;
  }
  .header-ex__logo a svg {
    max-width: 200px;
  }
  .news-list__item .img,
  .article-list__item .img {
    height: 440px;
  }
  .item-list__main {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
  }
  .item-list__main_head {
    display: none;
  }
  .item-list__main_results {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .item-list__main_row {
    position: relative;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 46%;
    margin-right: 2%;
    margin-bottom: 20px;
    border: 1px solid #e1e1e1;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .item-list__main_row:nth-child(even) {
    margin-right: 0;
  }
  .item-list__main_cell {
    display: block;
    min-width: 50%;
    border: none;
    text-align: left;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    margin-bottom: 5px;
  }
  .item-list__main_cell.provider {
    width: 100%;
    order: 1;
  }
  .item-list__main_cell.phone {
    width: 100%;
    order: 3;
  }
  .item-list__main_cell.price {
    width: 100%;
    order: 4;
  }
  .item-list__main_cell.stats {
    width: 100%;
    order: 2;
    display: none;
  }
  .item-list__main_cell.stats.active {
    display: block;
  }
  .item-list__main_cell.order {
    width: 100%;
    order: 5;
  }
  .order-item__execution .item-list__main_cell {
    min-width: 100%;
  }
  .item-list__main_row a.expand {
    display: block;
  }
  .item-list__price_tooltip {
    top: 25px;
    left: -55px;
  }
  .item-list__price_tooltip.units {
    top: -7px;
    left: unset;
    right: 0;
  }
  .item-card .img {
    min-height: 400px;
  }
  .item-card .btn.btn--primary.add {
    display: block;
  }
  .cabinet-menu-list {
    flex-wrap: wrap;
  }
  .cabinet-menu-list-item {
    width: 50%;
  }
  .cabinet-stat__controls button.btn {
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 5px;
  }
  .cabinet-head-links a {
    display: block;
  }
  .board-list__main {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
  }
  .board-list__main_results {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .board-list__main_results .board-list__main_row {
    border: 1px solid #e1e1e1;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .board-list__main_row {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 48%;
    margin-right: 2%;
  }
  .board-list__main_row:nth-child(even) {
    margin-right: 0;
  }
  .board-list__main_row a.expand {
    display: block;
    z-index: 666;
  }
  .board-list__main_row.filter-bar {
    flex-direction: row;
  }
  .board-list__main_cell {
    display: block;
    border: none;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
  }
  .board-list__main_cell.head {
    display: none;
  }
  .board-list__main_cell.filter {
    display: none;
    margin-bottom: 20px;
    margin-right: 5px;
  }
  .board-list__main_cell.filter.type {
    display: block;
    padding-left: 0;
  }
  .board-list__main_cell.filter.standard {
    display: block;
  }
  .board-list__main_cell.filter.label {
    display: block;
  }
  .board-list__main_cell.filter.reset {
    display: block;
    padding-right: 0;
  }
  .board-list__main_cell.stats {
    width: 100%;
    order: 1;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 10px;
  }
  .board-list__main_cell.type {
    width: 100%;
    order: 2;
    display: none;
  }
  .board-list__main_cell.standard {
    width: 100%;
    order: 3;
    display: none;
  }
  .board-list__main_cell.label {
    width: 100%;
    order: 4;
    display: none;
  }
  .board-list__main_cell.price {
    width: 100%;
    order: 5;
  }
  .board-list__main_cell.provider {
    width: 100%;
    order: 6;
    display: none;
  }
  .board-list__main_cell.order {
    width: 100%;
    order: 7;
  }
  .board-list__main_head {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
  }
  .board-list__main_head .board-list__main_cell.filter.type {
    width: auto;
    order: 1;
  }
  .board-list__main_head .board-list__main_cell.filter.standard {
    width: auto;
    order: 2;
  }
  .board-list__main_head .board-list__main_cell.filter.label {
    width: auto;
    order: 3;
  }
  .board-list__main_head .board-list__main_cell.filter.reset {
    order: 4;
  }
  .board-list-group .board-list__main_results {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .board-list-group .board-list__main_row {
    width: 48%;
    margin-right: 2%;
  }
  .board-list-group .board-list__main_row:nth-child(even) {
    margin-right: 0;
  }
  .board-list-group .board-list__main_cell {
    text-align: left;
    padding-left: 0;
  }
  .board-list-group .board-list__main_cell.stats {
    width: 100%;
    order: 1;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 10px;
  }
  .board-list-group .board-list__main_cell.type {
    width: 100%;
    order: 2;
    display: none;
  }
  .board-list-group .board-list__main_cell.standard {
    width: 100%;
    order: 3;
    display: none;
  }
  .board-list-group .board-list__main_cell.label {
    width: 100%;
    order: 4;
    display: none;
  }
  .board-list-group .board-list__main_cell.price {
    width: 100%;
    order: 5;
  }
  .board-list-group .board-list__main_cell.provider {
    width: 100%;
    order: 6;
    text-align: left;
  }
  .board-list-group .board-list__main_cell.add {
    width: 100%;
    order: 7;
  }
  .board-list-group .board-list__main_cell.filter {
    display: block;
  }
  .promo__scale:before {
    left: 0;
  }
  .promo-inner {
    height: 250px;
  }
  .promo-inner__scale {
    height: 250px;
  }
  .promo-inner__scale:after {
    content: '';
    position: absolute;
    top: -36px;
    right: 10%;
    width: 400px;
    height: 100%;
    background-color: #fff;
    -webkit-transform: rotate(-70deg);
    transform: rotate(-70deg);
    z-index: 1;
  }
  .promo-inner__wrapper {
    align-items: flex-start;
  }
  .provider-card__main {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
  }
  .provider-card__main_results {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
  }
  .provider-card__main_results .board-list__main_row {
    border: 1px solid #e1e1e1;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .provider-card__main_row {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid #e1e1e1;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .provider-card__main_cell {
    display: block;
    border: none;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  .provider-card__main_cell.head {
    display: none;
  }
  .provider-card__main_cell.filter {
    display: none;
    margin-bottom: 20px;
  }
  .provider-card__main_cell.filter.type {
    display: block;
    padding-left: 0;
  }
  .provider-card__main_cell.filter.standard {
    display: block;
  }
  .provider-card__main_cell.filter.label {
    display: block;
  }
  .provider-card__main_cell.filter.reset {
    display: block;
    padding-right: 0;
  }
  .provider-card__main_cell.stats {
    width: 25%;
    order: 1;
    margin-bottom: 0;
  }
  .provider-card__main_cell.type {
    width: 15%;
    order: 2;
  }
  .provider-card__main_cell.standard {
    width: 20%;
    order: 3;
  }
  .provider-card__main_cell.label {
    width: 20%;
    order: 4;
  }
  .provider-card__main_cell.price {
    width: 20%;
    order: 5;
  }
  .provider-card__main_cell.order {
    width: 100%;
    order: 7;
    text-align: left;
  }
  .catalog__col:nth-child(even) {
    border-right: none;
  }
  .catalog__item h2 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .catalog__item a {
    padding-left: 25px;
    padding-right: 20px;
  }
  .footer__copyright {
    flex-direction: row;
    margin-bottom: 30px;
  }
  .footer__copyright_logo {
    width: 50%;
    max-width: 300px;
    padding-right: 20px;
  }
  .footer__copyright_caption {
    width: 50%;
  }
  .footer__menu {
    justify-content: space-around;
  }
  .footer__sub .webmotor {
    text-align: left;
  }
  #city {
    max-width: 300px;
  }
  .footer-ex__copyright {
    order: 1;
  }
  .footer-ex__menu {
    order: 2;
  }
  .footer-ex__sub .warning,
  .footer-ex__sub .webmotor,
  .footer-ex__sub .company,
  .footer-ex__sub .policy {
    text-align: center;
  }
  .cabinet-rate-form:after {
    content: none;
  }
  /*news*/
  .news-title {
    flex-direction: column;
  }
  .news-title h1,
  .news-title .h1 {
    flex-grow: 0;
    flex-basis: 100%;
    min-width: 100%;
    margin-bottom: 5px;
  }
  .news-title h1 .tag-wrap,
  .news-title .h1 .tag-wrap {
    display: block;
    min-width: 100%;
    flex-basis: 100%;
    text-align: right;
  }
  .news-title .news-date {
    flex-grow: 0;
    flex-basis: 100%;
    min-width: 100%;
    padding-bottom: 10px;
  }
}
@media (max-width: 619px) {
  .header__contacts {
    flex-direction: column;
  }
  .news-list__item .img,
  .article-list__item .img {
    height: 350px;
  }
}
@media (min-width: 580px) {
  .header-ex__mob {
    display: none !important;
  }
  .nav-ex__main-mob {
    display: none !important;
  }
}
@media (max-width: 579px) {
  .header__main_top {
    height: auto;
  }
  .header__login {
    max-width: unset;
    z-index: unset;
  }
  .header__login.misc {
    justify-content: space-between;
    padding-right: 0;
  }
  .header__login.misc a:last-child {
    border-left: 1px solid #e1e1e1;
    border-right: none;
  }
  .header__login.user {
    padding-right: 0;
  }
  .header__login.user > a {
    border-right: none;
  }
  .header__login_dropdown {
    z-index: 3;
  }
  .header__social {
    position: static;
    justify-content: center;
    padding-left: 0;
    top: unset;
    order: 3;
    border-bottom: 1px solid #e1e1e1;
  }
  .header__social a {
    border-left: none;
    border-right: 1px solid #e1e1e1;
  }
  .header__social a:first-child {
    border-left: 1px solid #e1e1e1;
  }
  .header__counter {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 0;
    order: 2;
  }
  .header__counter_group {
    display: block;
  }
  .header__main_contacts {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 4px;
  }
  .header__contacts {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .header__contacts {
    padding-top: 0;
    height: 0;
  }
  .header__contacts_item.city {
    position: absolute;
    width: 65%;
    top: -33px !important;
    left: 50%;
    transform: translateX(-50%);
  }
  .header__contacts_item.city {
    text-align: center;
    padding-right: 0;
  }
  .header__contacts_item.city .fire-region {
    position: relative;
    left: -7px;
  }
  .header__logo {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    margin-bottom: 10px;
  }
  .header__logo a {
    justify-content: center;
  }
  .header__mob {
    left: 7px;
  }
  .header__mob-search {
    right: 5px;
  }
  .nav__main_search {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .nav__main_order {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    height: 49px;
  }
  .nav__main_search-wrapper {
    display: block !important;
  }
  .header__nav .nav__main_search a {
    border-right: none;
  }
  .header-ex {
    margin-top: 0;
    margin-bottom: 0;
  }
  .header-ex__main {
    height: 50px;
  }
  .header-ex__logo {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    text-align: center;
  }
  .header-ex__login {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    border-top: 1px solid #6e6e6e;
    display: none;
  }
  .sidebar #accordion {
    display: none;
  }
  .news-list__item .img,
  .article-list__item .img {
    height: 326px;
  }
  .news-list__filter {
    flex-direction: column;
  }
  .filter-simple {
    flex-direction: column;
    align-items: flex-start;
  }
  .filter-simple h1,
  .filter-simple .h1 {
    margin-bottom: 20px;
  }
  .board-list__main_row {
    width: 100%;
    margin-right: 0;
  }
  .board-list__main_results .board-list__main_cell {
    text-align: left;
  }
  .board-list__main_results .board-list__main_cell.stats {
    width: 100%;
    order: 1;
  }
  .board-list__main_results .board-list__main_cell.type {
    width: 50%;
    order: 2;
  }
  .board-list__main_results .board-list__main_cell.standard {
    width: 50%;
    order: 3;
  }
  .board-list__main_results .board-list__main_cell.label {
    width: 50%;
    order: 4;
  }
  .board-list__main_results .board-list__main_cell.price {
    width: 50%;
    order: 4;
  }
  .board-list__main_results .board-list__main_cell.provider {
    width: 100%;
    order: 5;
  }
  .board-list__main_results .board-list__main_cell.order {
    width: 100%;
    order: 6;
  }
  .board-list-group .board-list__main_row {
    width: 100%;
    margin-right: 0;
  }
  .board-list-group .board-list__main_cell {
    text-align: left;
  }
  .board-list-group .board-list__main_cell.stats {
    width: 100%;
    order: 1;
  }
  .board-list-group .board-list__main_cell.type {
    width: 33%;
    order: 2;
  }
  .board-list-group .board-list__main_cell.standard {
    width: 33%;
    order: 3;
  }
  .board-list-group .board-list__main_cell.label {
    width: 33%;
    order: 4;
  }
  .board-list-group .board-list__main_cell.price {
    width: 100%;
    order: 5;
  }
  .board-list-group .board-list__main_cell.provider {
    width: 100%;
    order: 6;
  }
  .board-list-group .board-list__main_cell.add {
    width: 100%;
    order: 7;
  }
  .board-list__main_row.filter-bar {
    flex-wrap: wrap;
  }
  .board-list-group .board-list__main_cell.filter {
    margin-bottom: 0;
  }
  .board-list__main_cell.filter .select2-container {
    min-width: 80px !important;
    width: 80px !important;
  }
  .board-list__main_head .board-list__main_cell.filter.reset {
    position: absolute;
    top: 2px;
    right: -10px;
  }
  .item-list__main_row {
    width: 100%;
    margin-right: 0;
  }
  .item-card .img {
    min-height: 350px;
  }
  .promo {
    height: auto;
  }
  .promo__scale {
    height: auto;
  }
  .promo__scale:before {
    transform: none;
    left: unset;
  }
  .promo__scale > div {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .promo__wrapper {
    padding-top: 16px;
    padding-bottom: 16px;
    justify-content: flex-start;
  }
  .promo__logo {
    margin-bottom: 10px;
  }
  .promo__adress {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .promo__phone {
    line-height: 1;
    margin-bottom: 10px;
  }
  .promo-inner__scale:after {
    content: '';
    position: absolute;
    top: -255px;
    right: -10%;
    width: 1000px;
    height: 1000px;
    background-color: #fff;
    -webkit-transform: rotate(-70deg);
    transform: rotate(-70deg);
    z-index: 1;
  }
  .promo-inner__phone {
    font-size: 20px;
  }
  .provider-card__main {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    display: none;
  }
  .provider-card__main_row {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid #e1e1e1;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .provider-card__main_cell {
    display: block;
    border: none;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  .provider-card__main_cell.head {
    display: none;
  }
  .provider-card__main_cell.stats {
    width: 50%;
    order: 1;
    text-align: left;
  }
  .provider-card__main_cell.type {
    width: 50%;
    order: 2;
    text-align: left;
  }
  .provider-card__main_cell.standard {
    width: 50%;
    order: 3;
    text-align: left;
  }
  .provider-card__main_cell.label {
    width: 50%;
    order: 4;
    text-align: left;
  }
  .provider-card__main_cell.price {
    width: 50%;
    order: 5;
    text-align: left;
  }
  .provider-card__main_cell.order {
    width: 50%;
    order: 7;
    text-align: left;
  }
  .catalog__col {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    border-right: none;
  }
  .footer__copyright {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .footer__copyright_logo {
    width: 100%;
    padding-right: 0;
  }
  .footer__copyright_caption {
    width: 100%;
  }
  .footer__menu {
    justify-content: space-between;
  }
  .footer__contacts {
    flex-direction: column;
  }
  .footer__contacts_social {
    justify-content: center;
    margin-bottom: 20px;
  }
  .footer__contacts_phone {
    justify-content: space-between;
  }
  .footer-ex__main {
    display: none;
  }
  .left__orders-list {
    flex-direction: column;
  }
  .left__orders-list .sidebar {
    max-width: 100%;
    flex-basis: 100%;
  }
  .left__orders-list .banner__side-long {
    max-width: 100%;
    flex-basis: 100%;
  }
}
@media (max-width: 460px) {
  .item-list__main_cell.phone {
    display: inline-block;
    width: 50%;
  }
  .item-list__main_cell.order {
    display: inline-block;
    width: 50%;
  }
}
@media (max-width: 419px) {
  .header__main_contacts {
    padding-left: 16px;
    padding-right: 16px;
  }
  .header__contacts_item {
    text-align: left;
  }
  .header-ex__logo a {
    position: relative;
    left: 16px;
  }
  .nav__top_wrapper {
    flex-direction: column;
  }
  .header__nav .nav__top_item:first-child {
    margin-left: 18px;
  }
  .header__nav-ex .nav-ex__main_menu {
    justify-content: space-between;
  }
  .header__nav-ex .nav-ex__main_item a {
    padding-left: 0;
    padding-right: 0;
  }
  .header__nav-ex .nav-ex__main_item a:hover {
    color: #a73439;
    background-color: #fff;
  }
  .header__nav-ex .nav-ex__main_item {
    border-right: none;
  }
  .header__nav-ex .nav-ex__main_item:first-child a {
    padding-left: 10px;
  }
  .header__nav-ex .nav-ex__main_item:last-child a {
    padding-right: 10px;
  }
  .news-list__item .img,
  .article-list__item .img {
    height: 227px;
  }
  .news-list__filter_wrapper a {
    margin-right: 4px;
  }
  .item-list__main_cell.provider {
    width: 100%;
    margin-bottom: 15px;
  }
  .item-list__main_cell.phone {
    display: inline-block;
    width: 50%;
    min-width: unset;
    order: 4;
  }
  .item-list__main_cell.price {
    width: 100%;
    order: 3;
    margin-bottom: 30px;
  }
  .item-list__main_cell.stats {
    width: 100%;
    margin-bottom: 25px;
  }
  .item-list__main_cell.order {
    display: inline-block;
    width: 50%;
    min-width: unset;
  }
  .item-list__price_tooltip {
    left: 0;
  }
  .item-list__top .select2-container {
    position: relative;
    margin-bottom: 20px;
  }
  .item-card .img {
    min-height: 270px;
  }
  .filter-simple__group a {
    min-width: unset;
    margin-left: 5px;
  }
  .board-list__main_results .board-list__main_cell {
    text-align: left;
  }
  .board-list__main_results .board-list__main_cell.stats {
    width: 100%;
    order: 1;
  }
  .board-list__main_results .board-list__main_cell.type {
    width: 100%;
    order: 2;
  }
  .board-list__main_results .board-list__main_cell.standard {
    width: 100%;
    order: 3;
  }
  .board-list__main_results .board-list__main_cell.label {
    width: 100%;
    order: 4;
  }
  .board-list__main_results .board-list__main_cell.price {
    width: 100%;
    order: 4;
  }
  .board-list__main_results .board-list__main_cell.provider {
    width: 100%;
    order: 5;
  }
  .board-list__main_results .board-list__main_cell.order {
    width: 100%;
    order: 6;
  }
  .board-list-group .board-list__main_cell.provider {
    text-align: left;
  }
  .promo__phone {
    font-size: 20px;
  }
  .provider-card__more {
    left: unset;
    right: 16px;
  }
  .footer__copyright {
    margin-bottom: 15px;
  }
  .footer__copyright_caption p {
    line-height: 1.2;
  }
  .footer__menu {
    flex-direction: column;
    align-items: center;
  }
  .footer__menu_group {
    margin-left: 0;
    margin-bottom: 15px;
    align-items: center;
  }
  .footer__menu_group:last-child {
    margin-bottom: 0;
  }
  .footer__menu_group-heading {
    margin-bottom: 10px;
  }
  .footer__menu_group-item {
    line-height: 1.3;
  }
  .footer__contacts_phone {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .footer__contacts_phone-item {
    margin-right: 0;
  }
  #login .row:last-child .site-form-element-wrapper {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 320px) {
  .news-list__item .img,
  .article-list__item .img {
    height: 166px;
  }
}
@media (max-width: 320px) {
  .item-list__main_cell .head {
    display: block;
    font-weight: 600;
  }
}
